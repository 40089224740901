import React from "react";

export const ConversationIcon = () => {
  return (
    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5026 19.5674V21.503C19.5026 21.7053 19.3807 21.8877 19.1938 21.9651C19.0069 22.0425 18.7918 21.9997 18.6487 21.8567L16.3594 19.5674"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M19.0039 10.499H19.5077C20.887 10.499 22.0052 11.6172 22.0052 12.9965V17.0695C22.0052 18.4488 20.887 19.567 19.5077 19.567V19.567"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.3639 19.5667H13.4975C12.1182 19.5667 11 18.4485 11 17.0692V16.001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70452 12.9998L7.35104 15.3534C7.20798 15.4965 6.99283 15.5392 6.80591 15.4618C6.619 15.3844 6.49714 15.202 6.49714 14.9997V12.9998H6.49359C5.83121 12.9998 5.19596 12.7367 4.72759 12.2683C4.25921 11.8 3.99609 11.1647 3.99609 10.5023V6.49359C3.99609 5.11426 5.11426 3.99609 6.49359 3.99609H13.5036C14.8829 3.99609 16.0011 5.11426 16.0011 6.49359V10.5023C16.0011 11.1647 15.738 11.8 15.2696 12.2683C14.8012 12.7367 14.166 12.9998 13.5036 12.9998H9.70452Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8 6.99728H11.0013"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8 9.99923H9.00042"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.0039 16.0012H18.0047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
