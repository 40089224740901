import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { Button, Dropdown, Header, Text, Tooltip } from "../../uikit";
import classNames from "classnames";
import classes from "./CompanyLayout.module.scss";
import { BotsStore } from "../../stores/BotsStore";
import { observer } from "mobx-react-lite";
import { FullPageLoader } from "../../components/common/FullPageLoader/FullPageLoader";
import { BotDto } from "../../dto/api.dto";
import { KnowledgeBaseStore } from "../../stores/KnowledgeBaseStore";
import { useTranslation } from "react-i18next";
import { LocaleNamespace } from "../../plugins/i18n/namespaces";
import { useLocation } from "react-router";
import { UserProfileMenu } from "../../components/common/UserProfileMenu/UserProfileMenu";
import { UserStore } from "../../stores/UserStore";
import { CacheService } from "../../services/cache.service";

export const CompanyLayout = observer(() => {
  const { t } = useTranslation(LocaleNamespace.COMPANY__HEADER);

  const navigation = useNavigate();
  const location = useLocation();
  const [isLoadingCheckTelegramAuth, setIsLoadingCheckTelegramAuth] = useState(
    false
  );

  const init = useCallback(async () => {
    setIsLoadingCheckTelegramAuth(true);
    try {
      await KnowledgeBaseStore.fetchKnowledgeBases();

      if (
        !UserStore.user?.info.isFilledBusinessInfo &&
        !CacheService.get("visit-profile" + UserStore.user?.id)
      ) {
        navigation("/company/profile");
        return;
      }

      const isExistBots = await BotsStore.fetchBots();
      if (!isExistBots) {
        navigation("/company/bot-creation");
        return;
      }

      const isCreatingBot = CacheService.has("create-bot-form-values");
      if (isCreatingBot) {
        navigation("/company/bot-creation");
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingCheckTelegramAuth(false);
    }
  }, []);

  // TODO: fix rerender!!!
  useEffect(() => {
    init();
  }, [init]);

  if (
    isLoadingCheckTelegramAuth ||
    BotsStore.isLoadingBots ||
    KnowledgeBaseStore.isLoadingKnowledgeBases
  ) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <Header>
        {BotsStore.bots.length ? (
          <div className="container full-width row justify-xs-between items-xs-center">
            <div
              className={classNames([
                classes.botSelect,
                "ml-xs-20",
                "row justify-xs-between items-xs-center",
              ])}
            >
              <Dropdown<BotDto>
                selected={BotsStore.currentBot}
                options={BotsStore.bots}
                getLabel={(bot) => bot.name}
                square
                textColor="primary"
                onChange={BotsStore.setCurrentBot}
              />
              <Button
                icon="plus"
                backgroundColor="transparent"
                href="/company/bot-creation"
              />
            </div>

            <div className="row gc-30">
              <Button
                color={
                  location.pathname === "/company"
                    ? "primary"
                    : "black-lightest-4"
                }
                label={t("buttons.dashboard")}
                icon="user-profile"
                href="/company"
              />
              {/* TODO: enable in future versions */}
              {/* <Button
                color={
                  location.pathname.includes("/web-widget-constructor")
                    ? "primary"
                    : "black-lightest-4"
                }
                label={t("buttons.widget")}
                icon="conversation"
                href="/company/web-widget-constructor"
              /> */}
              <Button
                color={
                  location.pathname.includes("/mailing")
                    ? "primary"
                    : "black-lightest-4"
                }
                label={t("buttons.mailing-list")}
                icon="rocket"
                href="/company/mailing"
              />
              {/* TODO: enable in future versions */}
              {/* <Button
                color={
                  location.pathname.includes("/shop")
                    ? "primary"
                    : "black-lightest-4"
                }
                label={t("buttons.store")}
                icon="store"
                href="shop"
              /> */}

              {BotsStore.currentTransports?.telegram ? (
                <Button
                  color={
                    location.pathname.includes("/booking")
                      ? "primary"
                      : "black-lightest-4"
                  }
                  label={t("buttons.booking")}
                  icon="calendar"
                  href="/company/booking"
                />
              ) : (
                <Tooltip
                  text={t("buttons.booking-disabled-tooltip")}
                  textType="p"
                >
                  <Button
                    color={
                      location.pathname.includes("/booking")
                        ? "primary"
                        : "black-lightest-4"
                    }
                    label={t("buttons.booking")}
                    icon="calendar"
                    href="/company/booking"
                    textColor="white"
                    disabled
                  />
                </Tooltip>
              )}

              <Button
                color="black-lightest-4"
                onlyIcon
                icon="help"
                href="/company/faq"
              />
              <UserProfileMenu />
            </div>
          </div>
        ) : (
          <div className="full-width row justify-xs-center items-xs-center">
            <Text type="h2" color="primary">
              Logo
            </Text>
          </div>
        )}
      </Header>
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
});
