import React, { useMemo } from "react";
import classNames from "classnames";
import { IconProps } from "./icon.types";
import { icons } from "./data";
import styles from "./icon.module.scss";

export const Icon = ({
  name,
  color = "grey",
  hoverColor,
  size = "md",
  disabled,
}: IconProps) => {
  const classes = useMemo(() => {
    return [
      styles[size],
      `stroke_${color}`,
      !!hoverColor && `stroke_hover_${hoverColor}`,
      !!disabled && `stroke_darken_${color}`,
    ];
  }, [size, color, hoverColor, disabled]);

  return <div className={classNames(classes)}>{icons[name]}</div>;
};
