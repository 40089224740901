import React, { useEffect, useState } from "react";
import { Card, Text } from "../../../../uikit";
import { StatisticOperatorDto } from "../../../../dto/api.dto";
import { getOperatorStatistics } from "../../../../api/statistics";
import { StatisticNumber } from "../../../../components/common/StatisticNumber/StatisticNumber";
import { StatisticNumberType } from "../../../../components/common/StatisticNumber/StatisticNumber.types";
import { mapStatusToDirection } from "../../../../helpers/dynamic-status";

export const OperatorBookingStatistics: React.FC = () => {
  const [statistics, setStatistics] = useState<StatisticOperatorDto>();

  useEffect(() => {
    getOperatorStatistics().then(setStatistics).catch(console.error);
  }, []);

  const items: {
    label: string;
    value?: number;
    direction?: StatisticNumberType;
  }[] = [
    {
      label: "Пользователей",
      value: statistics?.userCount,
      direction: mapStatusToDirection(),
    },
    {
      label: "Всего",
      value: statistics?.appealsCount,
      direction: mapStatusToDirection(),
    },
    {
      label: "Новых",
      value: statistics?.newAppealsCount,
      direction: mapStatusToDirection(statistics?.newAppealsIcon),
    },
  ];

  return (
    <Card>
      <div className="row gc-10">
        <div className="mr-10">
          <Text type="h3" color="blue">
            7 дней
          </Text>
        </div>

        {items.map((item, index) => (
          <div key={index} className="row gc-20">
            <Text type="h3">{item.label}</Text>
            <StatisticNumber value={item.value} direction={item.direction} />
          </div>
        ))}
      </div>
    </Card>
  );
};
