import React from "react";

export const StatisticIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 8.5H14.5C13.9477 8.5 13.5 8.94772 13.5 9.5V14.5C13.5 15.0523 13.9477 15.5 14.5 15.5H15.5C16.0523 15.5 16.5 15.0523 16.5 14.5V9.5C16.5 8.94772 16.0523 8.5 15.5 8.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.5 11.5H8.5C7.94772 11.5 7.5 11.9477 7.5 12.5V14.5C7.5 15.0523 7.94772 15.5 8.5 15.5H9.5C10.0523 15.5 10.5 15.0523 10.5 14.5V12.5C10.5 11.9477 10.0523 11.5 9.5 11.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
