import React from "react";

export const LinkIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.95 12.707L19.778 9.87901C21.34 8.31701 21.34 5.78401 19.778 4.22201V4.22201C18.216 2.66001 15.683 2.66001 14.121 4.22201L11.293 7.05001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.05001 11.293L4.22201 14.121C2.66001 15.683 2.66001 18.216 4.22201 19.778V19.778C5.78401 21.34 8.31701 21.34 9.87901 19.778L12.707 16.95"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M5.63999 8.46L3.51999 7.76"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.45999 5.64L7.75999 3.52"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.36 15.54L20.49 16.24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15.54 18.36L16.24 20.49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.17001 14.83L14.83 9.17"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
