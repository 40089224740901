const dateFormatter = new Intl.DateTimeFormat("ru");
const dateTimeFormatter = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export function formatDate(date: number) {
  return dateFormatter.format(new Date(date));
}

export function formatDateTime(date: number) {
  return dateTimeFormatter.format(date);
}
