import React from "react";

export const HelpIcon = () => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.6875 7.68607V7.68607C7.92443 6.66494 8.85447 5.95693 9.90183 6.0004V6.0004C11.074 5.93547 12.0789 6.82869 12.1518 8.0004C12.1518 9.50431 10.0018 10.0004 10.0018 11.0004"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.125 13.75C10.125 13.819 10.069 13.875 10 13.875C9.93096 13.875 9.875 13.819 9.875 13.75C9.875 13.681 9.93096 13.625 10 13.625"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10 13.625V13.625C10.069 13.625 10.125 13.681 10.125 13.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
