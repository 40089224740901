import { UserLogoutDto } from "../dto/api.dto";
import { UserEntity } from "../models/user/user.model";
import { http } from "../plugins/axios/axios";

export async function signInCompany(
  email: string,
  password: string
): Promise<string> {
  const { data } = await http.post("/auth/common/login/company", {
    email,
    password,
  });
  return data.token;
}

export async function signInOperator(
  email: string,
  password: string
): Promise<string> {
  const { data } = await http.post("/auth/common/login/operator", {
    email,
    password,
  });
  return data.token;
}

export async function signUp(email: string) {
  await http.post("/auth/common/registration/company", {
    email,
  });
}

export async function resetPassword(email: string) {
  await http.post("/auth/common/reset-password", { email });
}

export async function logout() {
  const { data } = await http.post<UserLogoutDto>("/auth/common/logout");
  return data;
}

export async function getCurrentUser(): Promise<UserEntity> {
  const { data } = await http.get("/auth/common/current");
  return UserEntity.fromJSON(data);
}
