import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Outlet } from "react-router";
import { UserProfileMenu } from "../../components/common/UserProfileMenu/UserProfileMenu";
import { LocaleNamespace } from "../../plugins/i18n/namespaces";
import { Button, Header, InsideNotification } from "../../uikit";
import { SocketStore } from "../../stores/SocketStore";
import { SocketEvent } from "../../enum/socket-event.enum";

export const OperatorLayout = () => {
  const { t } = useTranslation(LocaleNamespace.OPERATOR__HEADER);
  const location = useLocation();

  const [newBookingCount, setNewBookingCount] = useState<number | null>(null);
  // const [newOrdersCount, setNewOrdersCount] = useState<number | null>(null);

  useEffect(() => {
    SocketStore.addEvent(SocketEvent.OPERATOR_BOOKING_NEW_COUNT, (value) => {
      const parsedValue = Number(value);
      setNewBookingCount(
        !Number.isNaN(parsedValue)
          ? parsedValue !== 0
            ? parsedValue
            : null
          : null
      );
    });

    // SocketStore.addEvent(SocketEvent.OPERATOR_ORDERS_NEW_COUNT, (value) => {
    //   const parsedValue = Number(value);
    //
    //   setNewOrdersCount(
    //     !Number.isNaN(parsedValue)
    //       ? parsedValue !== 0
    //         ? parsedValue
    //         : null
    //       : null
    //   );
    // });

    SocketStore.emit(SocketEvent.OPERATOR_BOOKING_NEW_COUNT);
    // SocketStore.emit(SocketEvent.OPERATOR_ORDERS_NEW_COUNT);
  }, []);

  return (
    <div>
      <Header>
        <div className="container full-width row justify-xs-end items-xs-center">
          <div className="row gc-30">
            <Button
              color={
                location.pathname === "/operator"
                  ? "primary"
                  : "black-lightest-4"
              }
              icon="user-profile"
              label={t("dashboard")}
              href="/operator"
            />

            {/* <Button
              label="Заказы"
              icon="document"
              color={
                location.pathname === "/operator/orders"
                  ? "primary"
                  : "black-lightest-4"
              }
              href="/operator/orders"
              right={
                newOrdersCount != null && newOrdersCount > 0 ? (
                  <InsideNotification value={newOrdersCount} />
                ) : undefined
              }
            /> */}
            <Button
              label="Брони"
              icon="calendar"
              color={
                location.pathname === "/operator/booking"
                  ? "primary"
                  : "black-lightest-4"
              }
              href="/operator/booking"
              right={
                newBookingCount != null && newBookingCount > 0 ? (
                  <InsideNotification value={newBookingCount} />
                ) : undefined
              }
            />
            <Button
              color="black-lightest-4"
              onlyIcon
              icon="help"
              href="/operator/faq"
            />
            <UserProfileMenu />
          </div>
        </div>
      </Header>
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};
