import axios from "axios";
import { autorun, makeAutoObservable, runInAction } from "mobx";
import {
  changeBotName,
  changeBotTextAnswerType,
  createBot,
  getCurrentBots,
} from "../api/bots";
import { BotDto, CreateBotDTO } from "../dto/api.dto";
import { Cache } from "../enum/cache.enum";
import { CreateBotError } from "../enum/create-bot-errors.enum";
import { KnowledgeBaseStore } from "./KnowledgeBaseStore";
import { BotTextAnswerType } from "../enum/bot-text-answer-type.enum";
import { BotTransportsDto } from "../dto/transports.dto";
import { getBotTransports } from "../api/transports";

class BotsStore {
  private _bots: BotDto[] = [];

  private _currentBot: BotDto | null = null;

  private _currentTransports: BotTransportsDto | null = null;

  private _isLoadingBots = false;

  private _isCreatingBot = false;

  private _isChangingBotName = false;

  private _isChangingBotTextAnswerType = false;

  createBotError?: CreateBotError;

  changeBotNameError = false;

  changeTextAnswerTypeError = false;

  isFirstFetch = true;

  get bots() {
    return this._bots;
  }

  get isCreatingBot() {
    return this._isCreatingBot;
  }

  get isChangingBotName() {
    return this._isChangingBotName;
  }

  get isChangingBotTextAnswerType() {
    return this._isChangingBotTextAnswerType;
  }

  get currentBot(): BotDto | null {
    if (!this._currentBot) {
      return null;
    }
    return this._currentBot;
  }

  get currentTransports(): BotTransportsDto | null {
    return this._currentTransports;
  }

  setCurrentBot = (newCurrentBot: BotDto) => {
    const currentBot = this._bots.find((bot) => {
      return newCurrentBot.id === bot.id;
    });

    this._currentBot = currentBot ?? null;
    if (this._currentBot) {
      localStorage.setItem(Cache.CURRENT_BOT_ID, this._currentBot.id);
    }

    window.location.replace("/company");
  };

  get isLoadingBots() {
    return this._isLoadingBots;
  }

  fetchBots = async () => {
    runInAction(() => {
      this._isLoadingBots = true;
    });

    try {
      const bots = await getCurrentBots();

      const currentCacheBotId = localStorage.getItem(Cache.CURRENT_BOT_ID);

      runInAction(() => {
        this._bots = bots;

        if (bots.length === 0) {
          return;
        }

        const currentBot =
          (currentCacheBotId
            ? this.bots.find((bot) => bot.id === currentCacheBotId)
            : this.bots[0]) ?? null;

        if (currentBot) {
          KnowledgeBaseStore.setCurrentBase(currentBot.knowledgeBaseId);
        }

        this._currentBot = currentBot;
      });

      if (this._currentBot) {
        await this.fetchCurrentTransports(this._currentBot.id);
      }

      return this._currentBot !== null;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      runInAction(() => {
        this._isLoadingBots = false;
      });
    }
  };

  fetchCurrentTransports = async (bodId: string) => {
    try {
      const currentTransports = await getBotTransports(bodId);

      runInAction(() => {
        this._currentTransports = currentTransports;
      });
    } catch (error) {
      console.log(error);
    }
  };

  async createBot(
    dto: CreateBotDTO
  ): Promise<{ id: string; wasEmpty: boolean } | null> {
    this._isCreatingBot = true;
    try {
      const newBot = await createBot(dto);

      let wasEmpty = this._bots.length === 0;

      this._bots = [...this._bots, newBot];
      this.setCurrentBot(newBot);

      this._isCreatingBot = false;
      return { id: newBot.id, wasEmpty };
    } catch (error) {
      console.log(error);
      this._isCreatingBot = false;

      if (!axios.isAxiosError(error)) {
        return null;
      }

      if (error.response?.data?.error === "UNAUTHORIZED") {
        throw new Error("UNAUTHORIZED");
      }
      if (error.response?.data.error === "SESSION_PASSWORD_NEEDED") {
        throw new Error("SESSION_PASSWORD_NEEDED");
      }

      this.createBotError = error.response?.data?.error;

      return null;
    }
  }

  changeName = async (name: string) => {
    if (!this.currentBot?.id) {
      return;
    }

    try {
      this._isChangingBotName = true;
      await changeBotName({
        botId: this.currentBot.id,
        name,
      });

      await this.fetchBots();
    } catch (error) {
      console.log(error);
      this.changeBotNameError = true;
      throw error;
    } finally {
      this._isChangingBotName = false;
    }
  };

  changeTextAnswerType = async (type: BotTextAnswerType) => {
    if (!this.currentBot?.id) {
      return;
    }

    try {
      this._isChangingBotTextAnswerType = true;
      await changeBotTextAnswerType(this.currentBot.id, {
        type,
      });

      await this.fetchBots();
    } catch (error) {
      console.log(error);
      this.changeTextAnswerTypeError = true;
      throw error;
    } finally {
      this._isChangingBotTextAnswerType = false;
    }
  };

  reset = () => {
    runInAction(() => {
      this._bots = [];
    });
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const botsStore = new BotsStore();

autorun(() => {
  if (!botsStore.currentBot) {
    return;
  }
  if (!botsStore.currentBot.knowledgeBaseId) {
    KnowledgeBaseStore.clearCurrentBase();
    return;
  }

  KnowledgeBaseStore.setCurrentBase(botsStore.currentBot.knowledgeBaseId);
});

export { botsStore as BotsStore };
