import React, { useCallback, useMemo, useState } from "react";
import { TabsProps } from "./tabs.types";
import { TabButtons } from "./components/tabButtons";

export const Tabs = ({ tabs, defaultTab, onChangeTab }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultTab ?? tabs[0].key);
  const changeTab = useCallback(
    (tabKey: string) => {
      setActiveTab(tabKey);
      onChangeTab?.(tabKey);
    },
    [onChangeTab]
  );

  const content = useMemo(() => {
    return tabs.find((tab) => tab.key === activeTab)?.render() ?? <></>;
  }, [activeTab, tabs]);

  return (
    <div>
      <TabButtons activeTab={activeTab} data={tabs} changeTab={changeTab} />
      <div className="mt-10">{content}</div>
    </div>
  );
};
