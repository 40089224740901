import { makeAutoObservable, runInAction } from "mobx";
import { Socket, io } from "socket.io-client";
import { UserStore } from "./UserStore";
import { SocketEvent } from "../enum/socket-event.enum";

const { REACT_APP_SOCKET_URL } = process.env;

type SocketEventHandler = (...data: unknown[]) => void;

type NewEvent = {
  event: SocketEvent;
  handler: SocketEventHandler;
};

type NewEmit = {
  event: SocketEvent;
  data: unknown;
};

class SocketStore {
  private socket: Socket | null = null;

  private newEvents: NewEvent[] = [];

  private newEmits: NewEmit[] = [];

  connect = () => {
    if (REACT_APP_SOCKET_URL === undefined) {
      return;
    }
    // if (this.socket !== null) {
    //   return;
    // }

    const baseURL = String(REACT_APP_SOCKET_URL);
    console.log(
      "🚀 ~ file: SocketStore.ts:36 ~ SocketStore ~ baseURL:",
      baseURL
    );

    this.socket = io(baseURL, { query: { token: UserStore.token } });
    this.socket.open();

    this.socket.on("connect", () => {
      if (this.newEvents.length === 0) {
        return;
      }

      for (const newEvent of this.newEvents) {
        this.socket?.on(newEvent.event, newEvent.handler);
      }

      for (const newEmit of this.newEmits) {
        this.socket?.emit(newEmit.event, newEmit.data);
      }

      runInAction(() => {
        this.newEvents = [];
        this.newEmits = [];
      });
    });
  };

  addEvent = (event: SocketEvent, handler: SocketEventHandler) => {
    console.log(
      "🚀 ~ file: SocketStore.ts:66 ~ SocketStore ~ addEvent:",
      event
    );

    if (this.socket === null || this.socket.connected === false) {
      runInAction(() => {
        this.newEvents = [...this.newEvents, { event, handler }];
      });
      return;
    }

    this.socket.on(event, handler);
  };

  emit = (event: SocketEvent, data?: Record<any, any>) => {
    if (this.socket === null || this.socket.connected === false) {
      runInAction(() => {
        this.newEmits = [...this.newEmits, { event, data }];
      });
      return;
    }

    this.socket.emit(event, data);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const socketStore = new SocketStore();
export { socketStore as SocketStore };
