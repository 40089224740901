import classNames from "classnames";
import React, { useMemo } from "react";
import styles from "./loader.module.scss";
import { LoaderProps } from "./loader.types";

export function Loader({ size = "md", color = "primary" }: LoaderProps) {
  const borderColor = useMemo(
    () => `border_top_${color} border_left_${color} border_right_${color}`,
    [color]
  );

  return (
    <div
      className={classNames([styles.loader, borderColor, styles[size]])}
    ></div>
  );
}
