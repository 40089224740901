import React from "react";

export const CheckCircleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 13.85V15C27.4985 17.6955 26.6256 20.3184 25.0117 22.4773C23.3977 24.6362 21.1291 26.2156 18.5442 26.9799C15.9593 27.7442 13.1966 27.6524 10.6681 26.7182C8.13959 25.7841 5.98081 24.0576 4.51369 21.7963C3.04658 19.5351 2.34973 16.8601 2.52709 14.1704C2.70445 11.4807 3.7465 8.92042 5.49784 6.87135C7.24918 4.82229 9.61597 3.39424 12.2452 2.8002C14.8745 2.20615 17.6253 2.47793 20.0875 3.57501"
        stroke="#77CC50"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 5L15 17.5125L11.25 13.7625"
        stroke="#77CC50"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
