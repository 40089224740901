import React from "react";

export const PencilIcon = () => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.04193 15.885L18.3219 5.60499C19.1029 4.82399 19.1029 3.55799 18.3219 2.77699L17.1999 1.65399C16.4189 0.872987 15.1529 0.872987 14.3719 1.65399L4.09093 11.935C3.94493 12.081 3.82293 12.249 3.72793 12.432L1.09793 17.528C0.651933 18.393 1.57893 19.321 2.44393 18.876L7.54293 16.25C7.72793 16.154 7.89593 16.032 8.04193 15.885Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.90991 12.19L7.78991 16.07"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
