import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router";
import {
  // addBookingToQueue,
  getBookingsByBot,
  readBookingNotification,
} from "../../../api/booking";
import { BookingOperatorDto } from "../../../dto/api.dto";
import { BookingOperatorRecordSort } from "../../../enum/booking-operator-record-sort.enum";
import { formatDate } from "../../../helpers/formatters/date.formatter";
import { LocaleNamespace } from "../../../plugins/i18n/namespaces";
// import { OperatorStore } from "../../../stores/OperatorStore";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Page,
  Text,
} from "../../../uikit";
import { Table } from "../../../uikit/components/table/table";
import { TableColumn } from "../../../uikit/components/table/table.types";
import { OperatorBookingStatistics } from "./components/OperatorBookingStatistics";
import { OperatorBookingModal } from "../../../components/modals/OperatorBookingModal/OperatorBookingModal";
// import { ToastStore } from "../../../stores/ToastStore";

export const OperatorBookingPage = () => {
  const { t } = useTranslation(LocaleNamespace.OPERATOR__BOOKING_PAGE);
  // const navigate = useNavigate();

  const sortOptions = [
    {
      label: t(`sort.${BookingOperatorRecordSort.DATE}`),
      value: BookingOperatorRecordSort.DATE,
    },
    {
      label: t(`sort.${BookingOperatorRecordSort.CONTACT_DATA}`),
      value: BookingOperatorRecordSort.CONTACT_DATA,
    },
  ];

  const [bookings, setBookings] = useState<BookingOperatorDto[]>([]);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState(new Date());
  const [sort, setSort] = useState(sortOptions[0]);

  const [isShowViewBooking, setIsShowViewBooking] = useState(false);
  const [
    selectedBooking,
    setSelectedBooking,
  ] = useState<BookingOperatorDto | null>(null);

  const read = useCallback(
    (id: string) => {
      readBookingNotification(id)
        .then(() => {
          setBookings(
            bookings.map((booking) => {
              if (booking.id === id) {
                // @ts-ignore
                booking.status = NotificationType.VIEWED;
              }
              return booking;
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [bookings]
  );

  const look = useCallback((item: BookingOperatorDto) => {
    setSelectedBooking(item);
    setIsShowViewBooking(true);
  }, []);

  // const toChat = useCallback(
  //   (item: BookingOperatorDto) => {
  //     if (item.user.id === OperatorStore.currentDialog?.id) {
  //       navigate("/operator");
  //       return;
  //     }

  //     addBookingToQueue(item.id)
  //       .then((status) => {
  //         if (status) {
  //           ToastStore.toastSuccess(t("notifications.to-chat.success"));
  //         } else {
  //           ToastStore.toastFailure(t("notifications.to-chat.failure"));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(
  //           "🚀 ~ file: OperatorOrdersPage.tsx:94 ~ OperatorOrdersPage ~ error:",
  //           error
  //         );
  //         ToastStore.toastFailure(t("notifications.to-chat.failure"));
  //       });
  //   },
  //   [navigate, t]
  // );

  const columns: TableColumn<BookingOperatorDto>[] = [
    {
      label: "user-nickname",
      renderCell: (item) => {
        return (
          <Text type="h3">
            {item.user.firstName} {item.user.lastName}
          </Text>
        );
      },
    },
    {
      label: "date",
      renderCell: (item) => {
        return <Text type="h3">{formatDate(item.date)}</Text>;
      },
    },
    {
      label: "actions",
      renderCell: (item) => {
        return (
          <div className="row gc-20">
            <Button
              label={t("look")}
              color="black-lightest-4"
              onClick={() => look(item)}
            />
            {/* TODO: Add in future versions! */}
            {/* <Button
              label={t("to-chat")}
              color="black-lightest-4"
              onClick={() => toChat(item)}
            /> */}
          </div>
        );
      },
    },
  ];

  const fetch = useCallback(() => {
    getBookingsByBot({
      contactData: search || undefined,
      // date: date.getTime(),
      sort: sort.value,
    })
      .then((data) => {
        setBookings(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [search, date, sort]);

  useEffect(() => {
    fetch();
  }, [fetch, search, date, sort]);

  return (
    <Page>
      <div className="mb-10">
        <OperatorBookingStatistics />
      </div>
      <div className="mb-10 row justify-xs-between">
        <div className="full-width row items-xs-center gc-20">
          <Input
            value={search}
            onChange={setSearch}
            placeholder={t("search.placeholder")}
          />

          <DatePicker value={date} onChange={setDate} />
        </div>
        <div className="full-width row justify-xs-end items-xs-center">
          <div>
            <Dropdown
              selected={sort}
              onChange={setSort}
              options={sortOptions}
              getLabel={(item) => item.label}
              textColor="blue"
            />
          </div>
        </div>
      </div>
      <Table columns={columns} data={bookings} />
      <OperatorBookingModal
        show={isShowViewBooking}
        hide={() => setIsShowViewBooking(false)}
        operatorBooking={selectedBooking}
        read={read}
      />
    </Page>
  );
};
