import React, { useCallback, useMemo, useState } from "react";
import { DropdownProps } from "./dropdown.types";
import { Button } from "../button";
import { List } from "../list";
import { Text } from "../text";
import classNames from "classnames";
import classes from "./dropdown.module.scss";

export const Dropdown = <T,>({
  options = [],
  color,
  placeholder = "Выбрать",
  outline,
  textColor = "white",
  onChange,
  getLabel,
  square,
  selected,
  textSize = "h3",
  listSize = "h3",
}: DropdownProps<T>) => {
  const [isOpenedList, setIsOpenedList] = useState(false);

  const listStyle = useMemo(() => {
    return [
      classes.list,
      "full-width bg_black-lightest-3",
      !square && "rounded",
    ];
  }, [square]);

  const dropdownLabel = useMemo(() => {
    return selected != null ? getLabel(selected) : placeholder;
  }, [getLabel, placeholder, selected]);

  const onSelect = useCallback(
    (selectedValue) => {
      setIsOpenedList(false);
      onChange(selectedValue);
    },
    [onChange]
  );

  const optionRender = (option: T) => {
    return (
      <div
        className={classNames(
          classes.listItem,
          "row ph-20 pv-30 bg_hover_black-lightest-2-5"
        )}
        onClick={() => onSelect(option)}
      >
        <Text type={listSize}>{getLabel(option)}</Text>
      </div>
    );
  };

  return (
    <div
      className={classNames(classes.page, "row justify-xs-end", "full-width")}
    >
      <div className={classNames(classes.dropdown, "full-width")}>
        <Button
          icon="arrowDown"
          label={dropdownLabel}
          color={color}
          textColor={textColor}
          textSize={textSize}
          outline={outline}
          full
          iconPosition="right"
          flat={!outline}
          onClick={() => setIsOpenedList(!isOpenedList)}
        ></Button>
      </div>
      {isOpenedList && (
        <div className={classNames(listStyle)}>
          <List items={options} renderItem={optionRender} />
        </div>
      )}
    </div>
  );
};
