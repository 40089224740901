import React from "react";

export const RefreshIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        d="M19.5 12C19.5 10.1435 18.7625 8.36301 17.4497 7.05025C16.137 5.7375 14.3565 5 12.5 5C10.5431 5.00736 8.66475 5.77095 7.25778 7.13111L5.5 8.88889"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 5V9H9.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 12C5.5 13.8565 6.2375 15.637 7.55025 16.9497C8.86301 18.2625 10.6435 19 12.5 19C14.4569 18.9926 16.3352 18.229 17.7422 16.8689L19.5 15.1111"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15H19.5V19"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
