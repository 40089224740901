import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FilePreviewProps, PreviewItem } from "./file-preview.type";
import { Text } from "../text";
import { Icon } from "../icon";
import classNames from "classnames";
import classes from "./file-preview.module.scss";
import { getBase64 } from "../../../helpers/file-to-base64";
import { getFileUrl } from "../../../helpers/file-url";

export function FilePreview({
  type,
  files = [],
  links = [],
  onRemove,
  editable = false,
}: FilePreviewProps) {
  const [fileObjects, setFileObjects] = useState<Array<PreviewItem>>([]);

  const fetch = useCallback(async (filesObj: File[], linksObj: string[]) => {
    const fileNames: Array<PreviewItem> = [];
    for (const link of linksObj) {
      const linkArray = link.split("/");
      fileNames.push({
        link: getFileUrl(link),
        name: linkArray[linkArray.length - 1],
        type: "link",
      });
    }

    for (const file of filesObj) {
      const link = await getBase64(file);
      fileNames.push({ name: file.name, type: "file", link });
    }

    setFileObjects(fileNames);
  }, []);

  const handleRemove = useCallback(
    (item: PreviewItem) => {
      if (!editable) {
        return;
      }
      const findItem =
        item.type === "link"
          ? links.find((link) => link === item.link.split("storage")[1])
          : files.find((file) => file.name === item.name);
      if (findItem && onRemove) {
        onRemove(type, item.type, findItem);
        return;
      }
      console.log("Cannot find removing item");
    },
    [editable, files, links, onRemove, type]
  );

  useEffect(() => {
    if (files.length === 0 && links.length === 0) {
      return;
    }

    fetch(files, links);
  }, [fetch, files, links]);

  const generatePreviews = useMemo(() => {
    switch (type) {
      case "audio":
        return (
          <div className="column gr-30">
            {fileObjects.map((audio) => {
              return (
                <div
                  className="row justify-xs-between items-xs-center"
                  key={audio.name}
                >
                  <audio
                    className={classNames(classes.audio)}
                    controls
                    src={audio.link}
                  />
                  {editable && (
                    <div onClick={() => handleRemove(audio)}>
                      <Icon name="trash" color="red" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      case "file":
        return (
          <div className="column gr-30">
            {fileObjects.map((file) => {
              return (
                <div
                  key={file.name}
                  className="row justify-xs-between items-xs-center"
                >
                  <div className="row items-xs-center gc-30">
                    <Icon name="document" />
                    <div className="ml-30">
                      <Text type="h3" color="blue">
                        {file.name}
                      </Text>
                    </div>
                  </div>
                  {editable && (
                    <div onClick={() => handleRemove(file)}>
                      <Icon name="trash" color="red" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      case "image":
        return (
          <div className="column g-20">
            {fileObjects.map((image) => {
              return (
                <div
                  className={classNames(classes.imagePlace)}
                  key={image.name}
                >
                  <img
                    className={classNames(classes.image)}
                    src={image.link}
                    alt="img"
                  />
                  {editable && (
                    <div
                      className={classNames(
                        classes.closeBtn,
                        "row justify-xs-center pointer"
                      )}
                      onClick={() => handleRemove(image)}
                    >
                      <Icon name="close" size="xs" color="red" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      case "video":
        return (
          <div className="column g-20">
            {fileObjects.map((video) => {
              return (
                <div
                  className={classNames(classes.imagePlace)}
                  key={video.name}
                >
                  <video
                    className={classNames(classes.image)}
                    key={video.name}
                    width="300"
                  >
                    <source src={video.link} />
                  </video>
                  {editable && (
                    <div
                      className={classNames(
                        classes.closeBtn,
                        "row justify-xs-center pointer"
                      )}
                      onClick={() => handleRemove(video)}
                    >
                      <Icon name="close" size="xs" color="red" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
    }
  }, [editable, fileObjects, handleRemove, type]);
  return <div className="mt-20">{generatePreviews}</div>;
}
