import { http } from "../plugins/axios/axios";
import { CreateKnowledgeBaseDto, KnowledgeBaseDto } from "../dto/api.dto";

export async function getKnowledgeBasesByUser() {
  const { data } = await http.get<KnowledgeBaseDto[]>("/knowledge-base");
  return data;
}

export async function createKnowledgeBase(params: CreateKnowledgeBaseDto) {
  const { data } = await http.post<KnowledgeBaseDto>("/knowledge-base", params);
  return data;
}

export async function updateKnowledgeBase(params: KnowledgeBaseDto) {
  const { data } = await http.patch<KnowledgeBaseDto>(
    "/knowledge-base",
    params
  );
  return data;
}

export async function getKnowledgeBaseById(id: string) {
  const { data } = await http.get<KnowledgeBaseDto>(
    `/knowledge-base/get/${id}`
  );
  return data;
}
