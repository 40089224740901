import React from "react";

export const SpaceRocketIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0996 12.1234L11.7006 16.4426C11.3025 16.761 10.7287 16.7292 10.3682 16.3688L7.63286 13.6334C7.27241 13.273 7.24063 12.6991 7.55907 12.3011L11.8783 6.90206C13.8533 4.43325 16.8435 2.99609 20.0051 2.99609V2.99609C20.5576 2.99609 21.0055 3.444 21.0055 3.99651V3.99651C21.0055 7.15813 19.5684 10.1483 17.0996 12.1234Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0021 13.001V17.3848C16.0021 17.7637 15.788 18.1101 15.4491 18.2796L13.0321 19.4881C12.7727 19.6178 12.4698 19.6285 12.2019 19.5176C11.934 19.4066 11.7274 19.1848 11.6357 18.9097L11 17.0026"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.99726 13.0001L5.09031 12.3645C4.81518 12.2728 4.59339 12.0662 4.4824 11.7982C4.37142 11.5303 4.38217 11.2274 4.51186 10.968L5.72037 8.55107C5.88982 8.21216 6.23619 7.99805 6.6151 7.99805H10.9989"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.74734 20.5909L2.99609 21.0042L3.40942 18.253C3.5435 17.3605 4.24412 16.6599 5.13663 16.5258V16.5258C5.78157 16.4289 6.43424 16.6438 6.89541 17.105C7.35658 17.5661 7.57151 18.2188 7.4746 18.8637V18.8637C7.34049 19.7562 6.63985 20.4568 5.74734 20.5909V20.5909Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
