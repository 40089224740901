import React from "react";

import { TooltipProps } from "./tooltip.types";
import styles from "./tooltip.module.scss";
import classNames from "classnames";
import { Text } from "../text/text";

export const Tooltip = ({
  children,
  text,
  place = "top",
  disabled = false,

  textType,
}: TooltipProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>

      {!disabled && text && (
        <div
          className={classNames(
            styles.tooltip,
            styles[place],
            "pv-20 ph-20",
            `bg_black-lightest-3`,
            `border_black`
          )}
        >
          <Text type={textType ?? "h1"} color="white">
            {text}
          </Text>
        </div>
      )}
    </div>
  );
};
