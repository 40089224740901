import React, { useCallback, useMemo, useRef, useState } from "react";
import { MenuItem, MenuProps } from "./menu.types";
import { List } from "../list";
import { Text } from "../text";
import classNames from "classnames";
import classes from "./menu.module.scss";
import { useOnClickOutside } from "../../../hooks/common/useOnClickOutside";

export const Menu = ({ activator, items = [] }: MenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef(null);

  const onHideMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useOnClickOutside(ref, onHideMenu);

  const listClasses = useMemo(() => {
    return [classes.list, "full-width bg_black-lightest-3"];
  }, []);

  const itemRender = (item: MenuItem): JSX.Element => {
    return (
      <div
        className="ph-10 pv-20 flex bg_hover_black-lightest-2-5"
        onClick={() => {
          item.action();
          onHideMenu();
        }}
      >
        <Text type="h3">{item.name}</Text>
      </div>
    );
  };

  return (
    <div className={classNames(classes.page, "full")}>
      <div
        className={classNames(classes.menu, "full-width")}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {activator}
      </div>
      {isMenuOpen && (
        <div className={classNames(listClasses)} ref={ref}>
          <List items={items} renderItem={itemRender} />
        </div>
      )}
    </div>
  );
};
