import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     // the translations
//     // (tip move them in a JSON file and import them,
//     // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
//     resources: {
//       en: {
//         translation: {
//           "Welcome to React": "Welcome to React and react-i18next",
//         },
//       },
//     },
//     // lng: "en", // if you're using a language detector, do not define the lng option
//     fallbackLng: "en",

//     interpolation: {
//       escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     },
//   });

// const languages = ["en", "es"];

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: "ru",
    load: "languageOnly",
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    interpolation: {
      escapeValue: false,
    },

    // defaultNS: "menus",
    // fallbackLng: "en",
    // debug: true,
    // whitelist: languages,
    // lng: "en",
    // backend: {
    //   loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
    //   addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    // },
  });

export default i18n;
