import React, { useCallback, useEffect, useState } from "react";
import {
  BreadCrumb,
  Breadcrumbs,
  Button,
  Dropdown,
  Input,
  OverlayLoading,
  Text,
} from "../../../../../uikit";
import classNames from "classnames";
import classes from "./CategoryPage.module.scss";
import { ProductTable } from "../ProductTable/ProductTable";
import { ProductModal } from "../../../../../components/modals/ProductModal/ProductModal";
import {
  CategoryDto,
  ProductCreateDto,
  ProductDto,
} from "../../../../../dto/api.dto";
import { ProductCategoryModal } from "../../../../../components/modals/ProductCategoryModal/ProductCategoryModal";
import { useNavigate, useParams } from "react-router";
import { ToastStore } from "../../../../../stores/ToastStore";
import { useProductCategoriesManager } from "../../hooks/useProductCategoriesManager";
import { useProductManager } from "../../hooks/useProductManager";
import { useFormik } from "formik";

const breadcrumbs: Array<BreadCrumb> = [
  {
    name: "Главная",
    path: "/company",
  },
  {
    name: "Магазин",
    path: "/company/shop",
  },
  { name: "Категория", path: "" },
];
export type FilterItem = { label: string; value: string };
export const filterOptions: Array<FilterItem> = [
  {
    label: "По новизне",
    value: "date",
  },
  {
    label: "По названию",
    value: "name",
  },
  {
    label: "По цене",
    value: "price",
  },
];

export type SearchFormData = {
  search: string;
};

export const CategoryPage = () => {
  const [product, setProduct] = useState<ProductDto>({
    categoryId: "",
    description: "",
    id: "",
    image: "",
    price: 0,
    title: "",
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState<CategoryDto>({ id: "", title: "" });
  const [isShowAddProductModal, setIsShowAddProductModal] = useState(false);
  const [isShowCategoryModal, setIsShowCategoryModal] = useState(false);
  const [filter, setFilter] = useState(filterOptions[0]);
  const {
    categories,
    fetchCategories,
    fetchCategory,
    isGettingCategory,
    removeCategory,
    updateCategory,
  } = useProductCategoriesManager();
  const {
    fetchProducts,
    products,
    isGettingProducts,
    addNewProduct,
    updateProduct,
  } = useProductManager();

  const openProductModal = useCallback((productModalObj) => {
    setProduct(productModalObj);
    setIsShowAddProductModal(true);
  }, []);

  const onSubmit = useCallback((values: SearchFormData) => {
    fetchProducts(values.search, id);
  }, []);

  const searchForm = useFormik<SearchFormData>({
    initialValues: {
      search: "",
    },
    onSubmit,
  });

  useEffect(() => {
    fetchCategories();
    fetchCategory(id as string)
      .then((item) => {
        if (item) {
          setCategory(item);
          fetchProducts("", item.id, filter.value);
        }
      })
      .catch(() => {
        ToastStore.toastFailure("Не удалось получить данные");
      });
  }, [id]);

  const saveCategoryName = useCallback((newName: string) => {
    updateCategory({ id: id as string, title: newName }).then(() => {
      fetchCategory(id as string).then((item) => {
        if (item) {
          setCategory(item);
        }
      });
      fetchCategories();
      fetchProducts(searchForm.values.search, id, filter.value);
    });

    setIsShowCategoryModal(false);
  }, []);

  const hideProductModal = useCallback(() => {
    setProduct({
      categoryId: "",
      description: "",
      id: "",
      image: "",
      price: 0,
      title: "",
    });
    setIsShowAddProductModal(false);
  }, []);

  const saveProduct = useCallback(
    (newProduct: ProductDto | ProductCreateDto) => {
      if ("id" in newProduct) {
        updateProduct(newProduct).then(() => {
          fetchProducts("", id, filter.value);
        });
        hideProductModal();
        return;
      }
      addNewProduct(newProduct).then(() => {
        hideProductModal();
        fetchProducts("", id, filter.value);
      });
    },
    []
  );

  const deleteCategory = useCallback(() => {
    removeCategory(id as string).then(() => {
      navigate("/company/shop");
    });
  }, []);

  const changeFilter = useCallback(
    (newFilter: FilterItem) => {
      setFilter(newFilter);
      fetchProducts(searchForm.values.search, id, newFilter.value);
    },
    [searchForm]
  );

  return (
    <OverlayLoading loading={isGettingCategory || isGettingProducts}>
      <section
        className={classNames(classes.categoryPage, "column g-10 pa-20")}
      >
        <div className="row justify-xs-between full-width">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <div className="row justify-xs-between full-width">
          <div className="row gc-20 items-xs-center">
            <Text type="h1">{category.title}</Text>
            <Button
              icon="pencil"
              flat
              onClick={() => setIsShowCategoryModal(true)}
            />
          </div>
          <div className="row justify-xs-start items-xs-center gc-20">
            <div className={classes.filter}>
              <Dropdown
                options={filterOptions}
                getLabel={(item: FilterItem) => item.label}
                selected={filter}
                textColor="blue"
                onChange={changeFilter}
              />
            </div>
            <form className={classes.search} onSubmit={searchForm.handleSubmit}>
              <Input
                value={searchForm.values.search}
                onChange={searchForm.handleChange("search")}
                placeholder="Найти товар"
              />
            </form>
            {/*<Button icon="download" color="black-lightest-3" />*/}
            {/*<Button icon="settings" color="black-lightest-3" />*/}
            <Button
              icon="trash"
              color="black-lightest-3"
              onClick={deleteCategory}
            />
          </div>
        </div>
        <div className="column gr-20">
          <Text type="h3">ТОВАРЫ В ЭТОЙ КАТЕГОРИИ</Text>
          <ProductTable
            products={products}
            isCategories={!!categories.length}
            onCreate={() => setIsShowAddProductModal(true)}
            onProductClick={(productObj) => openProductModal(productObj)}
          />
        </div>
        <ProductModal
          show={isShowAddProductModal}
          hide={() => setIsShowAddProductModal(false)}
          product={product}
          onSave={saveProduct}
          categories={categories}
        />
        <ProductCategoryModal
          show={isShowCategoryModal}
          hide={() => setIsShowCategoryModal(false)}
          categoryName={category.title}
          onSave={saveCategoryName}
        />
      </section>
    </OverlayLoading>
  );
};
