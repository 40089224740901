import {
  CategoryCreateDto,
  CategoryDeleteDto,
  CategoryDto,
  ProductCountDto,
  ProductCreateDto,
  ProductDeleteDto,
  ProductDto,
  ProductOperatorDto,
} from "../dto/api.dto";
import { ProductsOperatorRecordSort } from "../enum/products-operator-record-sort.enum";
import { http } from "../plugins/axios/axios";

export async function createProduct(product: ProductCreateDto) {
  const { data } = await http.post<ProductDto>("/product", product);
  return data;
}

export async function editProduct(product: ProductDto) {
  const { data } = await http.patch<ProductDto>("/product", product);
  return data;
}

export async function getProducts(params?: {
  name?: string;
  category?: string;
  sort?: string;
}) {
  const { data } = await http.get<ProductDto[]>("/product", { params });
  return data;
}

export async function getProduct(id: string) {
  const { data } = await http.get<ProductDto>(`/product/${id}`);
  return data;
}

export async function deleteProduct(id: string) {
  const { data } = await http.delete<ProductDeleteDto>(`/product/${id}`);
  return data;
}

export async function getProductsByBotCount() {
  const { data } = await http.get<ProductCountDto>(`/product/by-bot/count`);
  return data;
}

export async function getProductsByBot(params?: {
  name?: string;
  date?: number;
  sort?: ProductsOperatorRecordSort;
}) {
  const { data } = await http.get<ProductOperatorDto[]>("/product/by-bot", {
    params,
  });
  return data;
}

export async function readProductNotification(productId: string) {
  const { data } = await http.post<{ status: boolean }>(
    `/product/view-status/${productId}`
  );
  return data;
}

export async function addProductToQueue(productId: string) {
  const { data } = await http.post<boolean>(
    `/product/add-to-queue/${productId}`
  );

  return data;
}

// *---------------------------------------------------------------------------*

export async function createCategory(product: CategoryCreateDto) {
  const { data } = await http.post<CategoryDto>("/category", product);
  return data;
}

export async function editCategory(product: CategoryDto) {
  const { data } = await http.patch<CategoryDto>("/category", product);
  return data;
}

export async function getCategories() {
  const { data } = await http.get<CategoryDto[]>("/category");
  return data;
}

export async function getCategory(id: string) {
  const { data } = await http.get<CategoryDto>(`/category/${id}`);
  return data;
}

export async function deleteCategory(id: string) {
  const { data } = await http.delete<CategoryDeleteDto>(`/category/${id}`);
  return data;
}
