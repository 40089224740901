import React, { useCallback, useMemo } from "react";
import { ListProps } from "./list.types";
import classNames from "classnames";

export const List = <T,>({
  items,
  dense = false,
  getKey,
  renderItem,
}: ListProps<T>) => {
  const listClasses = useMemo(() => {
    const values = ["column full-width"];

    values.push(!dense ? "gr-30" : "gr-0");

    return values;
  }, [dense]);

  const getItemKey = useCallback(
    (item: T, index: number) => {
      return getKey?.(item, index) ?? index;
    },
    [getKey]
  );

  return (
    <div className={classNames(listClasses)}>
      {items.map((item, index) => (
        <div key={getItemKey(item, index)}>{renderItem(item)}</div>
      ))}
    </div>
  );
};
