import React from "react";
import { OverlayProps } from "./overlay.types";
import styles from "./overlay.module.scss";
import { Portal } from "../portal";

export const Overlay = ({ children }: OverlayProps) => {
  return (
    <Portal>
      <div className={styles.container} role="dialog">
        {children}
      </div>
    </Portal>
  );
};
