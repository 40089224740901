import React, { useCallback, useMemo } from "react";
import { Text } from "../text/text";
import { ButtonProps } from "./types/button.types";
import classNames from "classnames";
import styles from "./button.module.scss";
import { Icon } from "../icon";
import { useNavigate } from "react-router";
import { Loader } from "../loader";

export const Button = ({
  label,
  icon,
  iconPosition = "left",
  size = "md",
  color = "primary",
  textColor = "white",
  backgroundColor,
  disabled = false,
  onlyIcon = false,
  loading = false,
  onClick,
  outline,
  flat = false,
  href,
  full = false,
  type = "button",
  textSize = "h4",

  right,

  noPadding,
}: ButtonProps) => {
  const navigate = useNavigate();

  const classes = useMemo(() => {
    if (flat) {
      return [
        "pointer",
        "bg_transparent",
        "row items-xs-center justify-xs-start",
        full && "full",
        styles.flat,
      ];
    }
    const values = [
      styles.button,
      full && "full",
      `bg_${color}`,
      "row items-xs-center justify-xs-center",
      "pointer",
      "rounded",
      outline ? `border_${outline}` : styles.borderNone,
      backgroundColor && `bg_${backgroundColor}`,
      noPadding && "pa-0",
    ];

    if (outline) {
      values.push(styles.border, `border_${outline}`);
    } else {
      values.push(styles.borderNone);
    }

    if (!onlyIcon) {
      values.push("pv-30 ph-20");
    } else {
      values.push("justify-xs-center", styles.onlyIconPadding);
    }

    return values;
  }, [flat, full, color, outline, backgroundColor, noPadding, onlyIcon]);

  const iconClasses = useMemo(() => {
    return label ? "mr-30" : "";
  }, [label]);

  const innerColor = useMemo(() => {
    return disabled ? color : textColor;
  }, [disabled, color, textColor]);

  const iconComponent = useMemo(
    () =>
      icon ? (
        <Icon size={size} color={innerColor} name={icon} disabled={disabled} />
      ) : null,
    [icon, size, innerColor, disabled]
  );

  const isShowHoverLayout = useMemo(() => {
    return !flat && !disabled;
  }, [flat, disabled]);

  const handleClick = useCallback(() => {
    if (href) {
      navigate(href);
      return;
    }
    if (onClick) {
      onClick();
    }
  }, [href, navigate, onClick]);

  const buttonContent = useMemo(() => {
    return (
      <>
        {isShowHoverLayout && (
          <div className={classNames(styles.hoverLayer, "rounded")}></div>
        )}

        {icon && iconPosition === "left" && (
          <div className={classNames(iconClasses)}>{iconComponent}</div>
        )}
        {label && (
          <Text type={textSize} color={innerColor} disabled={disabled}>
            {label}
          </Text>
        )}
        {icon && iconPosition === "right" && (
          <div className="ml-30">{iconComponent}</div>
        )}

        {right && <div className="ml-30">{right}</div>}
      </>
    );
  }, [
    disabled,
    icon,
    iconClasses,
    iconComponent,
    iconPosition,
    innerColor,
    isShowHoverLayout,
    label,
    textSize,
    right,
  ]);

  return (
    <button
      className={classNames(classes)}
      disabled={disabled}
      type={type}
      onClick={handleClick}
    >
      {!loading ? buttonContent : <Loader size="xs" color="white" />}
    </button>
  );
};
