import {
  BookingCountDto,
  BookingCreateDto,
  BookingDto,
  BookingOperatorDto,
} from "../dto/api.dto";
import { BookingOperatorRecordSort } from "../enum/booking-operator-record-sort.enum";
import { http } from "../plugins/axios/axios";

export async function createBooking(newBooking: BookingCreateDto) {
  const { data } = await http.post<BookingDto>("/booking", newBooking);
  return data;
}

export async function getBookings() {
  const { data } = await http.get<BookingDto[]>("/booking");
  return data;
}

export async function updateBooking(id: string, booking: BookingDto) {
  const { data } = await http.patch(`/booking/${id}`, booking);
  return data;
}

export async function getBookingsByBotCount() {
  const { data } = await http.get<BookingCountDto>(`/booking/by-bot/count`);
  return data;
}

export async function getBookingsByBot(params?: {
  contactData?: string;
  date?: number;
  sort?: BookingOperatorRecordSort;
}) {
  const { data } = await http.get<BookingOperatorDto[]>("/booking/by-bot", {
    params,
  });
  return data;
}

export async function readBookingNotification(bookingId: string) {
  const { data } = await http.post<{ status: boolean }>(
    `/booking/view-status/${bookingId}`
  );
  return data;
}

export async function getBooking(id: string) {
  const { data } = await http.get<BookingDto>(`/booking/${id}`);
  return data;
}

export async function deleteBooking(id: string) {
  await http.delete(`/booking/${id}`);
}

export async function addBookingToQueue(bookingId: string) {
  const { data } = await http.post<boolean>(
    `/booking/add-to-queue/${bookingId}`
  );

  return data;
}
