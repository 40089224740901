export class UserIndividualEntrepreneurInfoEntity {
  constructor(
    private readonly _firstName: string,
    private readonly _lastName: string,
    private readonly _middleName: string | null,
    private readonly _registrationAddress: string,
    private readonly _inn: string,
    private readonly _phone: string,
    private readonly _checkingAccount: string,
    private readonly _BIKBank: string,
    private readonly _bankName: string,
    private readonly _corporateAccount: string
  ) {}

  public get firstName() {
    return this._firstName;
  }

  public get lastName() {
    return this._lastName;
  }

  public get middleName() {
    return this._middleName;
  }

  public get registrationAddress() {
    return this._registrationAddress;
  }

  public get inn() {
    return this._inn;
  }

  public get phone() {
    return this._phone;
  }

  public get checkingAccount() {
    return this._checkingAccount;
  }

  public get BIKBank() {
    return this._BIKBank;
  }

  public get bankName() {
    return this._bankName;
  }

  public get corporateAccount() {
    return this._corporateAccount;
  }

  static fromJSON(obj: any = {}) {
    return new UserIndividualEntrepreneurInfoEntity(
      obj.firstName,
      obj.lastName,
      obj.middleName,
      obj.registrationAddress,
      obj.inn,
      obj.phone,
      obj.checkingAccount,
      obj.BIKBank,
      obj.bankName,
      obj.corporateAccount
    );
  }
}
export class UserOrganizationInfoEntity {
  constructor(
    private readonly _name: string,
    private readonly _OGRN: string,
    private readonly _inn: string,
    private readonly _kpp: string,
    private readonly _legalAddress: string,
    private readonly _actualAddress: string,
    private readonly _checkingAccount: string,
    private readonly _BIKBank: string,
    private readonly _bankName: string,
    private readonly _corporateAccount: string,
    private readonly _phone: string,
    private readonly _CEO: string
  ) {}

  public get name() {
    return this._name;
  }

  public get OGRN() {
    return this._OGRN;
  }

  public get inn() {
    return this._inn;
  }

  public get kpp() {
    return this._kpp;
  }

  public get legalAddress() {
    return this._legalAddress;
  }

  public get actualAddress() {
    return this._actualAddress;
  }

  public get checkingAccount() {
    return this._checkingAccount;
  }

  public get BIKBank() {
    return this._BIKBank;
  }

  public get bankName() {
    return this._bankName;
  }

  public get corporateAccount() {
    return this._corporateAccount;
  }

  public get phone() {
    return this._phone;
  }

  public get CEO() {
    return this._CEO;
  }

  static fromJSON(obj: any = {}) {
    return new UserOrganizationInfoEntity(
      obj.name,
      obj.OGRN,
      obj.inn,
      obj.kpp,
      obj.legalAddress,
      obj.actualAddress,
      obj.checkingAccount,
      obj.BIKBank,
      obj.bankName,
      obj.corporateAccount,
      obj.phone,
      obj.CEO
    );
  }
}
export class UserSelfEmployedInfoEntity {
  constructor(
    private readonly _firstName: string,
    private readonly _lastName: string,
    private readonly _middleName: string,
    private readonly _inn: string,
    private readonly _correspondentAccountNumber: string,
    private readonly _accountNumber: string,
    private readonly _bankBIK: string,
    private readonly _bankName: string
  ) {}

  public get firstName() {
    return this._firstName;
  }

  public get lastName() {
    return this._lastName;
  }

  public get middleName() {
    return this._middleName;
  }

  public get inn() {
    return this._inn;
  }

  public get correspondentAccountNumber() {
    return this._correspondentAccountNumber;
  }

  public get accountNumber() {
    return this._accountNumber;
  }

  public get bankBIK() {
    return this._bankBIK;
  }

  public get bankName() {
    return this._bankName;
  }

  static fromJSON(obj: any = {}) {
    return new UserSelfEmployedInfoEntity(
      obj.firstName,
      obj.lastName,
      obj.middleName,
      obj.inn,
      obj.correspondentAccountNumber,
      obj.accountNumber,
      obj.bankBIK,
      obj.bankName
    );
  }
}

export class UserInfoEntity {
  constructor(
    private readonly _id: string,
    private readonly _email: string,
    private readonly _individualEntrepreneurInfo?: UserIndividualEntrepreneurInfoEntity,
    private readonly _organizationInfo?: UserOrganizationInfoEntity,
    private readonly _selfEmployed?: UserSelfEmployedInfoEntity,
    private readonly _firstName?: string,
    private readonly _lastName?: string,
    private readonly _avatar?: string
  ) {}

  public get id() {
    return this._id;
  }

  public get email() {
    return this._email;
  }

  public get firstName() {
    return this._firstName;
  }

  public get lastName() {
    return this._lastName;
  }

  public get individualEntrepreneurInfo() {
    return this._individualEntrepreneurInfo;
  }

  public get organizationInfo() {
    return this._organizationInfo;
  }

  public get selfEmployed() {
    return this._selfEmployed;
  }

  public get isFilledBusinessInfo() {
    return (
      this._individualEntrepreneurInfo !== undefined ||
      this._organizationInfo !== undefined ||
      this._selfEmployed !== undefined
    );
  }

  public get initials() {
    if (this.firstName?.length && this.lastName?.length) {
      return `${this.firstName[0].toUpperCase()}${this.lastName[0].toUpperCase()}`;
    }
    if (this.firstName?.length) {
      return `${this.firstName[0].toUpperCase()}${this.firstName?.[1] ?? ""}`;
    }
    if (this.lastName?.length) {
      return `${this.lastName[0].toUpperCase()}${this.lastName?.[1] ?? ""}`;
    }

    return this.email.substring(0, 2);
  }

  public get avatar() {
    return this._avatar;
  }

  static fromJSON(obj: any = {}) {
    return new UserInfoEntity(
      obj.id,
      obj.email,
      obj.individualEntrepreneurInfo
        ? UserIndividualEntrepreneurInfoEntity.fromJSON(
            obj.individualEntrepreneurInfo
          )
        : undefined,
      obj.organizationInfo
        ? UserOrganizationInfoEntity.fromJSON(obj.organizationInfo)
        : undefined,
      obj.selfEmployed
        ? UserSelfEmployedInfoEntity.fromJSON(obj.selfEmployed)
        : undefined,
      obj.firstName,
      obj.lastName,
      obj.avatar
    );
  }
}
