import React from "react";

export const VideoIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.19995 11.2H16.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10 11.2L10.8 8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.2 11.2L14 8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.7175 5.28247C20.0461 6.61107 20.9509 8.30381 21.3175 10.1466C21.684 11.9894 21.4959 13.8996 20.7769 15.6355C20.0578 17.3714 18.8402 18.8551 17.2779 19.899C15.7157 20.9428 13.8789 21.5 12 21.5C10.1211 21.5 8.28435 20.9428 6.72208 19.899C5.15981 18.8551 3.94218 17.3714 3.22314 15.6355C2.50411 13.8996 2.31598 11.9894 2.68255 10.1466C3.04911 8.30381 3.9539 6.61107 5.2825 5.28247C7.0641 3.50088 9.48046 2.5 12 2.5C14.5196 2.5 16.9359 3.50088 18.7175 5.28247"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15.8 16H8.19995C7.93473 16 7.68038 15.8946 7.49284 15.7071C7.30531 15.5196 7.19995 15.2652 7.19995 15V9C7.19995 8.73478 7.30531 8.48043 7.49284 8.29289C7.68038 8.10536 7.93473 8 8.19995 8H15.8C16.0652 8 16.3195 8.10536 16.5071 8.29289C16.6946 8.48043 16.8 8.73478 16.8 9V15C16.8 15.2652 16.6946 15.5196 16.5071 15.7071C16.3195 15.8946 16.0652 16 15.8 16V16Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
