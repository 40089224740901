import { Permission } from "../../enum/permission.enum";
import { UserRole } from "../../enum/roles.enum";

export class PermissionEntity {
  constructor(
    public readonly id: string,
    public readonly title: string,
    public readonly value: Permission
  ) {}
}

export class UserAuthEntity {
  constructor(
    private readonly _permissions: PermissionEntity[],
    private readonly _role: UserRole
  ) {}

  public get permissions() {
    return this._permissions.map((p) => p.title);
  }

  public get role() {
    return this._role;
  }

  static fromJSON(obj: any = {}) {
    return new UserAuthEntity(
      obj.permissions?.map(
        (p: any) => new PermissionEntity(p.id, p.title, p.value)
      ) ?? [],
      obj.role as UserRole
    );
  }
}
