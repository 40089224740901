import React from "react";
import type { FC } from "react";
import { Text } from "../text";

export const InsideNotification: FC<{ value: number }> = ({ value }) => {
  return (
    <div className="bg_red ph-30" style={{ borderRadius: 8 }}>
      <Text type="h6" color="white">
        {value}
      </Text>
    </div>
  );
};
