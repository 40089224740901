import React, { useCallback, useMemo } from "react";
import { FilePickerProps } from "./file-picker.types";
import styles from "./file-picker.module.scss";
import classNames from "classnames";
import { Text } from "../text";
import { Accept, useDropzone } from "react-dropzone";
import { Button } from "../button";

export function FilePicker({
  type,
  multiple,
  onChange,
  value = [],
  onRemove,
}: FilePickerProps) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange([...value, ...acceptedFiles]);
    },
    [onChange, value]
  );

  const label = useMemo(() => {
    switch (type) {
      case "image":
        return "Изоражения";
      case "video":
        return "Видео";
      case "file":
        return "Файлы";
      case "audio":
        return "Аудио";
      default:
        return "";
    }
  }, [type]);

  const acceptedTypes = useMemo((): Accept => {
    switch (type) {
      case "image":
        return { "image/*": [".png", ".jpg", ".jpeg"] };
      case "video":
        return { "video/*": [".mp4"] };
      case "file":
        return { "application/*": [".docx", ".doc", ".txt", ".pdf"] };
      case "audio":
        return { "audio/*": [".mp3"] };
      default:
        return { "text/*": [".txt"] };
    }
  }, [type]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedTypes,
  });

  return (
    <div>
      <div className="row justify-xs-between">
        <Text type="h2">{label}</Text>
        {onRemove && (
          <Button icon="trash" color="transparent" onClick={onRemove} />
        )}
      </div>
      <div
        className={classNames(
          styles.inputPlace,
          "pa-10 row justify-xs-center",
          "items-xs-center pointer mt-20"
        )}
        {...getRootProps()}
      >
        <input
          className={styles.input}
          multiple={multiple}
          type="file"
          {...getInputProps()}
        />
        <Text type="h3">
          Перетащите {label.toLowerCase()} сюда или нажмите на поле{" "}
        </Text>
      </div>
    </div>
  );
}
