import React from "react";

export const StoreIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 21H21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4 21L4.0001 10.9554"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M20.0735 21L20.0736 10.941"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21.7243 7.47765C21.8704 7.75278 21.96 8.05432 21.988 8.36457C22.0159 8.67482 21.9816 8.98753 21.887 9.28433C21.7262 9.79079 21.4056 10.2315 20.9734 10.5407C20.5412 10.8499 20.0205 11.0108 19.4892 10.9994C18.958 10.9881 18.4446 10.805 18.026 10.4777C17.6074 10.1503 17.306 9.69628 17.1669 9.18341C17.1581 9.15362 17.1399 9.12748 17.115 9.10889C17.0901 9.09029 17.0599 9.08025 17.0288 9.08025C16.9978 9.08025 16.9675 9.09029 16.9426 9.10889C16.9177 9.12748 16.8995 9.15362 16.8907 9.18341C16.7488 9.70495 16.4392 10.1653 16.0097 10.4935C15.5803 10.8217 15.0547 10.9994 14.5142 10.9994C13.9737 10.9993 13.4482 10.8214 13.0188 10.4932C12.5894 10.1649 12.28 9.70447 12.1381 9.1829C12.1293 9.1531 12.1111 9.12694 12.0863 9.10834C12.0614 9.08973 12.0311 9.07968 12 9.07968C11.969 9.07968 11.9387 9.08973 11.9138 9.10834C11.8889 9.12694 11.8707 9.1531 11.8619 9.1829C11.7201 9.70447 11.4105 10.1649 10.9811 10.4931C10.5516 10.8213 10.0261 10.9992 9.48556 10.9991C8.94503 10.9991 8.41952 10.8213 7.99009 10.493C7.56065 10.1647 7.25115 9.70431 7.10931 9.18273C7.10048 9.15295 7.08226 9.12681 7.05737 9.10823C7.03247 9.08964 7.00224 9.0796 6.97118 9.0796C6.94011 9.0796 6.90988 9.08964 6.88499 9.10823C6.86009 9.12681 6.84188 9.15295 6.83305 9.18273C6.69393 9.69555 6.39255 10.1496 5.97397 10.4769C5.5554 10.8042 5.04212 10.9872 4.51089 10.9986C3.97966 11.01 3.45902 10.8491 3.02682 10.54C2.59462 10.2309 2.27408 9.7902 2.11313 9.28381C2.01851 8.98696 1.98417 8.6742 2.01211 8.36389C2.04005 8.05358 2.12972 7.75198 2.27585 7.47681L3.98257 4.09822C4.14941 3.76794 4.4046 3.49042 4.71977 3.29654C5.03493 3.10265 5.3977 3 5.76773 3H18.232C18.6021 3 18.9648 3.10266 19.28 3.29654C19.5952 3.49043 19.8504 3.76794 20.0172 4.09822L21.7243 7.47765Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14 21V10.941"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14 16H4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
