import React, { useMemo } from "react";
import classNames from "classnames";
import { CardProps } from "./card.types";

export const Card = ({
  color = "black-lightest-1",
  padding = "lg",
  hoverColor,
  fullWidth = false,
  fullHeight = false,
  children,
}: CardProps) => {
  const paddingStyle = useMemo(() => {
    switch (padding) {
      case "xs":
        return "pv-30 ph-20";
      case "md":
        return "pa-20";
      case "lg":
        return "pa-10";
      default:
        return "pa-10";
    }
  }, [padding]);

  return (
    <div
      className={classNames(
        paddingStyle,
        "rounded",
        hoverColor && `bg_hover_${hoverColor}`,
        fullWidth && "full-width",
        fullHeight && "full-height",
        `bg_${color}`
      )}
    >
      {children}
    </div>
  );
};
