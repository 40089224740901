import React, { useMemo } from "react";
import { UserStatusProps } from "./user-status.types";
import styles from "./user-status.module.scss";
import { Color } from "../../types/color.type";
import classNames from "classnames";

export const UserStatus = ({ status, children }: UserStatusProps) => {
  const color: Color = useMemo(() => {
    if (status === "online") {
      return "green";
    }
    if (status === "busy") {
      return "yellow";
    }
    if (status === "offline") {
      return "red";
    }

    return "red";
  }, [status]);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.status, `bg_${color}`)}></div>
      {children}
    </div>
  );
};
