import React, { Suspense } from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import { routes } from "./routers/router";
import { useRouter } from "./uikit";
import "./plugins/i18n";
import { FullPageLoader } from "./components/common/FullPageLoader/FullPageLoader";

const App = () => {
  const { routes: pages } = useRouter(routes);

  return (
    <BrowserRouter>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>{pages}</Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
