import { makeAutoObservable } from "mobx";
import toast from "react-hot-toast";
import { generateToast } from "../components/common/Toast/Toast";

class ToastStore {
  toastSuccess(message: string) {
    toast(generateToast(message), {
      style: { background: "green" },
    });
  }

  toastFailure(message: string) {
    toast(generateToast(message), { style: { background: "red" } });
  }

  toastInfo(message: string) {
    toast(generateToast(message), { style: { background: "DodgerBlue" } });
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const toastStore = new ToastStore();
export { toastStore as ToastStore };
