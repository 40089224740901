export enum LocaleNamespace {
  COMPANY__DASHBOARD_PAGE = "company__dashboard-page",
  COMPANY__KNOWLEDGE_BASE_PAGE = "company__knowledge-base-page",
  COMPANY__KNOWLEDGE_BASE = "company__knowledge-base",
  COMPANY__RESOURCES = "company__resources",
  COMPANY__AUTH_PAGE = "company__auth-page",
  COMPANY__CREATE_BOT_PAGE = "company__create-bot-page",
  COMPANY__TELEGRAM_AUTH_PAGE = "company__telegram-auth-page",
  COMPANY__HEADER = "company__header",
  COMPANY__MAILING = "company__mailing-page",
  COMPANY__WEB_WIDGET_CONSTRUCTOR = "company__web-widget-constructor",
  COMPANY__SENDING_DATA_FORM = "company__sending-data-form",
  COMPANY__CREATE_OPERATOR = "company__create-operator",
  COMPANY_CHANGE_BOT_NAME_MODAL = "company__change-bot-name-modal",
  COMPANY_CHANGE_BOT_TEXT_ANSWER_TYPE_MODAL = "company__change-bot-text-answer-type-modal",
  COMPANY__CREATE_PRODUCT_CATEGORY_MODAL = "company__create-product-category-modal",
  COMPANY__CREATE_PRODUCT_MODAL = "company__create-product-modal",
  COMPANY__BOT_MESSAGES_SETTINGS_MODAL = "company__bot-messages-settings-modal",
  COMPANY__BOT_BUTTONS_MODAL = "company__bot-buttons-modal",
  COMPANY__BOOKING_PAGE = "company__booking-page",
  COMPANY__TELEGRAM_TRANSPORT_PAGE = "company__telegram-transport",

  OPERATOR__HEADER = "operator__header",
  OPERATOR__DASHBOARD_PAGE = "operator__dashboard-page",
  OPERATOR__BOOKING_PAGE = "operator__booking-page",
  OPERATOR__PRODUCTS_PAGE = "operator__products-page",

  USER_PROFILE_PAGE = "user_profile_page",

  BUTTONS = "buttons",

  ATTACHMENTS_FORM = "attachments-form",
}
