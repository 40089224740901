import React from "react";

export const EditIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g opacity="0.7">
        <path
          d="M8.04199 15.885L18.322 5.60499C19.103 4.82399 19.103 3.55799 18.322 2.77699L17.2 1.65399C16.419 0.872987 15.153 0.872987 14.372 1.65399L4.09099 11.935C3.94499 12.081 3.82299 12.249 3.72799 12.432L1.09799 17.528C0.651994 18.393 1.57899 19.321 2.44399 18.876L7.54299 16.25C7.72799 16.154 7.89599 16.032 8.04199 15.885Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.90999 12.19L7.78999 16.07"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
