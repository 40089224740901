import React from "react";
import { Router } from "../uikit";
import { AuthPage } from "../pages/AuthPage/AuthPage";
import { AuthLayout } from "../layouts/AuthLayout/AuthLayout";
import { CompanyLayout } from "../layouts/CompanyLayout/CompanyLayout";
import { AuthCheckerLayout } from "../layouts/AuthCheckerLayout/AuthCheckerLayout";
import { OperatorLayout } from "../layouts/OperatorLayout/OperatorLayout";
import { OperatorFaqPage } from "../pages/Operator/OperatorFaqPage/OperatorFaqPage";
import { CompanyFaqPage } from "../pages/Company/CompanyFaqPage/CompanyFaqPage";
import { CategoryPage } from "../pages/Company/CompanyShopPage/components/CategoryPage/CategoryPage";
import { OperatorBookingPage } from "../pages/Operator/OperatorBookingPage/OperatorBookingPage";
// import { OperatorOrdersPage } from "../pages/Operator/OperatorOrdersPage/OperatorOrdersPage";

const CompanyDashboardPage = React.lazy(() =>
  import("../pages/Company/CompanyDashboard/DashboardPage")
);
const CompanyTelegramAuthPage = React.lazy(() =>
  import("../pages/Company/CompanyTelegramAuthPage/CompanyTelegramAuthPage")
);
const CompanyCreationBotPage = React.lazy(() =>
  import("../pages/Company/CompanyCreateBotPage/CreateBotPage")
);
const CompanyKnowledgeBase = React.lazy(() =>
  import("../pages/Company/CompanyKnowledgeBase/KnowledgeBase")
);
const CompanyKnowledgeBaseEditor = React.lazy(() =>
  import(
    "../pages/Company/CompanyKnowledgeBaseSettings/CompanyKnowledgeBaseEditor"
  )
);
const CompanyKnowledgeBaseCreator = React.lazy(() =>
  import(
    "../pages/Company/CompanyKnowledgeBaseSettings/CompanyKnowledgeBaseCreator"
  )
);
const CompanyWebWidgetConstructor = React.lazy(() =>
  import(
    "../pages/Company/CompanyWebWidgetConstructorPage/CompanyWebWidgetConstructorPage"
  )
);
const CompanyTelegramTransportPage = React.lazy(() =>
  import(
    "../pages/Company/CompanyTelegramTransportPage/CompanyTelegramTransportPage"
  )
);
const CompanyMailingPage = React.lazy(() =>
  import("../pages/Company/CompanyMailingPage/CompanyMailingPage")
);
const CompanyCreateMailingPage = React.lazy(() =>
  import("../pages/Company/CompanyMailingPage/subpages/CreateMailingPage")
);
const CompanyEditMailingPage = React.lazy(() =>
  import("../pages/Company/CompanyMailingPage/subpages/EditMailingPage")
);
const CompanyBookingPage = React.lazy(() =>
  import("../pages/Company/CompanyBookingPage/BookingPage")
);

const OperatorDashboardPage = React.lazy(() =>
  import("../pages/Operator/OperatorDashboardPage/OperatorDashboardPage")
);

const UserProfilePage = React.lazy(() =>
  import("../pages/Common/UserProfilePage/UserProfilePage")
);

// const ShopPage = React.lazy(() =>
//   import("../pages/Company/CompanyShopPage/ShopPage")
// );

export const routes: Router[] = [
  {
    path: "",
    component: <AuthCheckerLayout />,
    children: [
      {
        path: "/auth",
        component: <AuthLayout />,
        children: [{ path: "", component: <AuthPage /> }],
      },
      {
        path: "/company",
        component: <CompanyLayout />,
        children: [
          {
            path: "",
            component: <CompanyDashboardPage />,
          },
          {
            path: "telegram-auth",
            component: <CompanyTelegramAuthPage />,
          },
          {
            path: "bot-creation",
            component: <CompanyCreationBotPage />,
          },
          {
            path: "knowledge-base",
            component: <CompanyKnowledgeBase />,
          },
          {
            path: "knowledge-base/edit/:id",
            component: <CompanyKnowledgeBaseEditor />,
          },
          {
            path: "knowledge-base/create",
            component: <CompanyKnowledgeBaseCreator />,
          },
          {
            path: "mailing",
            component: <CompanyMailingPage />,
          },
          {
            path: "mailing/create",
            component: <CompanyCreateMailingPage />,
          },
          {
            path: "mailing/edit/:id",
            component: <CompanyEditMailingPage />,
          },
          {
            path: "booking",
            component: <CompanyBookingPage />,
          },
          {
            path: "profile",
            component: <UserProfilePage />,
          },
          // TODO: enable in future versions
          // {
          //   path: "shop",
          //   component: <ShopPage />,
          // },
          {
            path: "category/:id",
            component: <CategoryPage />,
          },
          {
            path: "faq",
            component: <CompanyFaqPage />,
          },
          {
            path: "web-widget-constructor",
            component: <CompanyWebWidgetConstructor />,
          },
          {
            path: "telegram-transport",
            component: <CompanyTelegramTransportPage />,
          },
        ],
      },
      {
        path: "/operator",
        component: <OperatorLayout />,
        children: [
          {
            path: "",
            component: <OperatorDashboardPage />,
          },
          {
            path: "profile",
            component: <UserProfilePage />,
          },
          {
            path: "booking",
            component: <OperatorBookingPage />,
          },
          // {
          //   path: "orders",
          //   component: <OperatorOrdersPage />,
          // },

          {
            path: "faq",
            component: <OperatorFaqPage />,
          },
        ],
      },
    ],
  },
];
