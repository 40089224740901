import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LocaleNamespace } from "../../../plugins/i18n/namespaces";
import { UserStore } from "../../../stores/UserStore";
import { Button, Menu, MenuItem } from "../../../uikit";
import classNames from "classnames";
import classes from "./UserProfileMenu.module.scss";
import { computed } from "mobx";
import { UserRole } from "../../../enum/roles.enum";

export const UserProfileMenu = observer(() => {
  const { t } = useTranslation(LocaleNamespace.COMPANY__HEADER);

  const navigate = useNavigate();

  const logout = useCallback(() => {
    UserStore.logout();
    navigate("/auth");
  }, []);

  const menu: Array<MenuItem> = computed(() => {
    if (!UserStore.user) {
      return [];
    }

    const logoutItem = {
      name: t("buttons.user-menu.logout"),
      action: logout,
    };

    switch (UserStore.user.auth.role) {
      case UserRole.COMPANY:
        return [
          {
            name: t("buttons.user-menu.profile"),
            action: () => navigate("/company/profile"),
          },
          logoutItem,
        ];

      case UserRole.OPERATOR:
        return [logoutItem];
    }
  }).get();

  return (
    <div className={classNames(classes.squareBtn, "pointer")}>
      <Menu
        activator={<Button color="black-lightest-4" onlyIcon icon="settings" />}
        items={menu}
      />
    </div>
  );
});
