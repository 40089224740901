import { CacheValue } from "../types/cache-value.types";

export class CacheService {
  static get<T>(key: CacheValue | string) {
    const cachedValue = localStorage.getItem(key);
    if (!cachedValue) {
      return null;
    }

    try {
      return JSON.parse(cachedValue) as T;
    } catch {
      return (cachedValue as unknown) as T;
    }
  }

  static set(key: CacheValue | string, value: unknown) {
    if (!value) {
      return;
    }

    localStorage.setItem(
      key,
      typeof value !== "string" ? JSON.stringify(value) : value
    );
  }

  static has(key: CacheValue | string) {
    const item = localStorage.getItem(key);
    return item !== null && item !== undefined;
  }

  static clear(key: CacheValue | string) {
    localStorage.removeItem(key);
  }

  static clearAll() {
    localStorage.clear();
  }
}
