import React from "react";
import { SeparatorProps } from "./separator.types";
import styles from "./separator.module.scss";
import classNames from "classnames";

export const Separator = ({
  orientation,
  color = "grey",
  size = "xs",
}: SeparatorProps) => {
  return (
    <div
      className={classNames(
        styles[orientation],
        styles[`${orientation}_${size}`],
        `bg_${color}`
      )}
    ></div>
  );
};
