import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { PortalProps } from "./portal.types";

export const Portal = ({ children }: PortalProps) => {
  const [container] = useState(() => document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: fix any!
  return ReactDOM.createPortal(children as any, container);
};
