import { useCallback, useState } from "react";
import { CategoryCreateDto, CategoryDto } from "../../../../dto/api.dto";
import {
  createCategory,
  deleteCategory,
  editCategory,
  getCategories,
  getCategory,
} from "../../../../api/store";

export const useProductCategoriesManager = () => {
  const [isGettingCategories, setIsGettingCategories] = useState(false);
  const [isGettingCategory, setIsGettingCategory] = useState(false);
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const [categories, setCategories] = useState<CategoryDto[]>([]);

  const fetchCategories = useCallback(() => {
    setIsGettingCategories(true);
    getCategories()
      .then((items) => {
        setCategories(items);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsGettingCategories(false);
      });
  }, []);

  const fetchCategory = useCallback(async (categoryId: string) => {
    setIsGettingCategory(true);
    try {
      return await getCategory(categoryId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsGettingCategory(false);
    }
  }, []);

  const addNewCategory = useCallback(async (item: CategoryCreateDto) => {
    setIsCreatingCategory(true);
    try {
      const newCategory = await createCategory(item);
      setCategories((oldCategories) => [...oldCategories, newCategory]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreatingCategory(false);
    }
  }, []);

  const updateCategory = useCallback(async (item: CategoryDto) => {
    setIsUpdatingCategory(true);
    try {
      return await editCategory(item);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdatingCategory(false);
    }
  }, []);

  const removeCategory = useCallback(async (categoryId: string) => {
    setIsDeletingCategory(true);
    try {
      return await deleteCategory(categoryId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeletingCategory(false);
    }
  }, []);

  return {
    fetchCategories,
    fetchCategory,
    categories,
    addNewCategory,
    updateCategory,
    removeCategory,
    isGettingCategories,
    isGettingCategory,
    isCreatingCategory,
    isUpdatingCategory,
    isDeletingCategory,
  };
};
