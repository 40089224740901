import React, { useMemo } from "react";
import classNames from "classnames";
import { useTag } from "./hooks/useTag";
import { TextProps } from "./text.types";
import styles from "./text.module.scss";

export const Text = ({
  type,
  color = "white",
  hoverColor,
  children,
  disabled,
  center = false,
}: TextProps) => {
  const Tag = useTag({ type });

  const classes = useMemo(() => {
    const values = [
      styles.text,
      center && "text-xs-center",
      styles[type],
      `text_${color}`,
      `text_disabled_${color}`,
    ];
    if (disabled) {
      values.push(`text_darken_${color}`);
    }
    if (hoverColor) {
      values.push(`text_hover_${hoverColor}`);
    }

    return values;
  }, [center, type, color, disabled, hoverColor]);

  return <Tag className={classNames(classes)}>{children}</Tag>;
};
