import React from "react";
import classNames from "classnames";
import { HeaderProps } from "./header.types";
import styles from "./header.module.scss";

export const Header = ({
  color = "black-lightest-1",
  children,
}: HeaderProps) => {
  return (
    <div
      className={classNames(
        styles.header,
        "row items-xs-center",
        "full-width",
        `bg_${color}`
      )}
    >
      {children}
    </div>
  );
};
