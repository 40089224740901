import React from "react";
import { BreadcrumbsProps } from "./breadcrumbs.types";
import { Button } from "../button";
import { Text } from "../text/text";

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <div className="row g-20 items-xs-start">
      {breadcrumbs.map((breadcrumb) => {
        return (
          <div key={breadcrumb.path} className="row g-20">
            <Button
              key={breadcrumb.path}
              flat
              href={breadcrumb.path}
              label={breadcrumb.name}
            />
            {breadcrumbs[breadcrumbs.length - 1] != breadcrumb && (
              <Text type="h4">/</Text>
            )}
          </div>
        );
      })}
    </div>
  );
};
