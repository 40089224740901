import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Text } from "../../../uikit";
import { useTranslation } from "react-i18next";
import { LocaleNamespace } from "../../../plugins/i18n/namespaces";

export type ProductCategoryModalProps = {
  show: boolean;
  hide: () => void;
  categoryName?: string;
  onSave: (name: string) => void;
};
export const ProductCategoryModal = ({
  show = false,
  hide,
  categoryName = "",
  onSave,
}: ProductCategoryModalProps) => {
  const { t } = useTranslation(
    LocaleNamespace.COMPANY__CREATE_PRODUCT_CATEGORY_MODAL
  );
  const { t: tBtn } = useTranslation(LocaleNamespace.BUTTONS);

  const [name, setName] = useState(categoryName);

  useEffect(() => {
    if (categoryName) {
      setName(categoryName);
    } else {
      setName("");
    }
  }, [show, categoryName]);

  return (
    <div>
      <Modal
        onClose={hide}
        isShow={show}
        title={<Text type="h2">{t("title")}</Text>}
      >
        <div className="mt-10 column gr-20 items-xs-center">
          <div className="column gr-20 full-width">
            <Input
              value={name}
              blurred
              onChange={setName}
              placeholder={t("fields.name")}
            />
          </div>
          <div className="row justify-xs-end gc-10">
            <Button label={tBtn("cancel")} flat onClick={hide} />
            <Button
              label={tBtn("save")}
              disabled={name?.length === 0}
              onClick={() => onSave(name)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
