import { makeAutoObservable } from "mobx";
import {
  getCurrentUser,
  signInCompany,
  signInOperator,
  signUp,
} from "../api/auth";
import { Cache } from "../enum/cache.enum";
import { UserEntity } from "../models/user/user.model";
import { BotsStore } from "./BotsStore";
import { KnowledgeBaseStore } from "./KnowledgeBaseStore";
import { UserRole } from "../enum/roles.enum";

class UserStore {
  user: UserEntity | null = null;

  isLoading: boolean = true;

  isLoadingCheckTelegramAuth = false;

  token: string | null = null;

  telegramAuth = false;

  isFirstFetch = true;

  get isAuth() {
    return this.user !== null;
  }

  setUser(user: UserEntity) {
    console.log(user);
    this.user = user;
  }

  setToken(token: string) {
    this.token = token;
    localStorage.setItem(Cache.TOKEN, token);
  }

  logout = async () => {
    this.isLoading = true;
    try {
      localStorage.removeItem(Cache.TOKEN);
      localStorage.removeItem(Cache.TG_AUTH);
      localStorage.removeItem(Cache.CURRENT_BOT_ID);

      BotsStore.reset();
      KnowledgeBaseStore.clearCurrentBase();

      this.token = null;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  fetchToken() {
    const token = localStorage.getItem(Cache.TOKEN);
    this.token = token ?? null;
  }

  async signIn(email: string, password: string, role: UserRole) {
    try {
      const request =
        role === UserRole.COMPANY ? signInCompany : signInOperator;

      const token = await request(email, password);
      this.setToken(token);

      await this.fetchUser();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async signUp(email: string) {
    try {
      await signUp(email);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async fetchUser() {
    this.isLoading = true;
    try {
      const user = await getCurrentUser();
      this.user = user;
      return user;
    } catch (error) {
      console.log(error);
      throw new Error();
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const userStore = new UserStore();
export { userStore as UserStore };
