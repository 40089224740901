import { getKnowledgeBaseItems } from "../api/knowledge-base-items";
import { getKnowledgeBasesByUser } from "../api/knowlenge-base";
import { KnowledgeBaseDto, KnowledgeBaseItemDto } from "../dto/api.dto";

class KnowledgeBaseStore {
  private _isLoadingKnowledgeBases = false;

  private _isLoadingKnowledgeBaseItems = false;

  private _knowledgeBases: KnowledgeBaseDto[] = [];

  private _knowledgeBaseItems: KnowledgeBaseItemDto[] = [];

  private _currentBase: KnowledgeBaseDto | null = null;

  get isLoadingKnowledgeBases() {
    return this._isLoadingKnowledgeBases;
  }

  get isLoadingKnowledgeBaseItems() {
    return this._isLoadingKnowledgeBaseItems;
  }

  get currentBase() {
    return this._currentBase;
  }

  setCurrentBase = (id: string) => {
    this._currentBase =
      this._knowledgeBases.find((base) => base.id === id) ?? null;

    if (this._currentBase) {
      this.fetchKnowledgeBaseItems(this._currentBase.id);
    }
  };

  clearCurrentBase = () => {
    this._currentBase = null;
    this._knowledgeBaseItems = [];
  };

  get knowledgeBases() {
    return this._knowledgeBases;
  }

  get knowledgeBaseItems() {
    return this._knowledgeBaseItems;
  }

  public fetchKnowledgeBases = async () => {
    this._isLoadingKnowledgeBases = true;
    try {
      const bases = await getKnowledgeBasesByUser();
      this._knowledgeBases = bases;
    } catch (error) {
      console.log(error);
    } finally {
      this._isLoadingKnowledgeBases = false;
    }
  };

  public fetchKnowledgeBaseItems = (baseId: string) => {
    this._isLoadingKnowledgeBaseItems = true;
    getKnowledgeBaseItems(baseId)
      .then((items) => {
        this._knowledgeBaseItems = items;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this._isLoadingKnowledgeBaseItems = false;
      });
  };
}

const knowledgeBaseStore = new KnowledgeBaseStore();
export { knowledgeBaseStore as KnowledgeBaseStore };
