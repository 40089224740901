import React from "react";
import { UserStatus } from "../user-status";
import { AvatarProps } from "./avatar.types";
import classNames from "classnames";
import styles from "./avatar.module.scss";

export const Avatar = ({
  src,
  status,
  size = "md",
  cover = true,
}: AvatarProps) => {
  const img = (
    <img
      className={classNames(
        styles.avatar,
        styles[size],
        cover ? styles.cover : ""
      )}
      src={src}
      alt="user-avatar"
    />
  );

  if (status) {
    return <UserStatus status={status}>{img}</UserStatus>;
  }

  return img;
};
