import {
  BotButtonsDto,
  BotDto,
  ChangeBotButtonsDto,
  ChangeBotKnowledgeBaseDto,
  ChangeBotKnowledgeBaseStatusDto,
  ChangeBotMessagesSettingsDto,
  ChangeBotNameDto,
  ChangeBotTextAnswerTypeDTO,
  CreateBotDTO,
} from "../dto/api.dto";
import { http } from "../plugins/axios/axios";

export async function getCurrentBots() {
  const { data } = await http.get<BotDto[]>("/bots");
  return data;
}

export async function createBot(dto: CreateBotDTO) {
  const { data } = await http.post<BotDto>("/bots", dto);
  return data;
}

export async function updateBotKnowledgeBase(
  botId: string,
  dto: ChangeBotKnowledgeBaseDto
) {
  const { data } = await http.patch<ChangeBotKnowledgeBaseStatusDto>(
    `/bots/${botId}/knowledge-base`,
    dto
  );
  return data;
}

export async function changeBotName(dto: ChangeBotNameDto) {
  const { data } = await http.post<BotDto>(
    "/telegram/bot/change-bot-name",
    dto
  );
  return data;
}

export async function changeBotTextAnswerType(
  botId: string,
  dto: ChangeBotTextAnswerTypeDTO
) {
  const { data } = await http.patch<BotDto>(`/bots/${botId}/answer-type`, dto);
  return data;
}

export async function changeBotMessages(
  botId: string,
  dto: ChangeBotMessagesSettingsDto
) {
  const { data } = await http.patch<BotDto>(`/bots/${botId}/messages`, dto);
  return data;
}

export async function getBotButtons(botId: string) {
  const { data } = await http.get<BotButtonsDto>(
    `/telegram/bot/bot-buttons/${botId}`
  );
  return data;
}

export async function changeBotButtons(
  botId: string,
  dto: ChangeBotButtonsDto
) {
  const { data } = await http.post<BotButtonsDto>(
    `/telegram/bot/change-bot-buttons`,
    {
      botId,
      ...dto,
    }
  );
  return data;
}
