import React from "react";
import classNames from "classnames";
import { Loader } from "../../../uikit";
import styles from "./FullPageLoader.module.scss";

export function FullPageLoader() {
  return (
    <div
      className={classNames(
        styles.loader,
        "row justify-xs-center items-xs-center"
      )}
    >
      <Loader size="lg" color="primary" />
    </div>
  );
}
