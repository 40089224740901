import React from "react";
import { ProductTableProps } from "./ProductTable.types";
import classNames from "classnames";
import classes from "./ProductTable.module.scss";
import { Button, Text } from "../../../../../uikit";
import { getFileUrl } from "../../../../../helpers/file-url";

export const ProductTable = ({
  products = [],
  onCreate,
  onProductClick,
  isCategories = true,
}: ProductTableProps) => {
  const formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    maximumFractionDigits: 0,
  });

  return (
    <div className={classNames(classes.page, "row g-10")}>
      <div className={classes.productItem}>
        <div className={classes.icon}>
          <Button
            full
            icon="plus"
            disabled={!isCategories}
            color={isCategories ? "black-lightest-4" : "black-lightest-2-5"}
            onClick={onCreate}
          />
        </div>
      </div>
      {products.map((product, index) => {
        return (
          <div
            key={index}
            className={classNames(
              classes.productItem,
              "column items-xs-center gr-30"
            )}
            onClick={() => onProductClick(product)}
          >
            <div className={classNames(classes.icon, "mb-30")}>
              <img src={getFileUrl(product.image)} />
            </div>
            <Text center type="h3">
              {product.title}
            </Text>
            <Text center type="h2">
              {formatter.format(product.price)}
            </Text>
          </div>
        );
      })}
    </div>
  );
};
