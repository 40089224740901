import React from "react";

export const SendIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1272_1986)">
        <path
          d="M5.51708 12L4.05093 5.44206C3.96602 5.06228 3.99375 4.66597 4.13073 4.30171C4.2677 3.93746 4.50798 3.62107 4.82209 3.39134C5.13621 3.16161 5.51053 3.02851 5.89917 3.00836C6.28781 2.98821 6.67389 3.08187 7.01008 3.27788L19.0073 10.2722C19.3094 10.4483 19.56 10.7005 19.7342 11.0037C19.9083 11.3068 20 11.6504 20 12C20 12.3497 19.9083 12.6932 19.7342 12.9964C19.56 13.2995 19.3094 13.5517 19.0073 13.7278L7.01008 20.7221C6.67389 20.9181 6.28782 21.0118 5.89918 20.9917C5.51055 20.9715 5.13624 20.8384 4.82212 20.6087C4.50801 20.379 4.26774 20.0626 4.13075 19.6983C3.99377 19.3341 3.96603 18.9378 4.05093 18.558L5.51708 12Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 12L20 12"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1272_1986">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(24) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
