import React from "react";

export const AudioIcon = () => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49996 18.933C5.78896 18.474 3.49096 16.799 2.17896 14.478"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.15 4.12598C1.812 5.70898 1 7.75798 1 10.001C1 10.56 1.058 11.105 1.154 11.637"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11.5 18.933C14.211 18.474 16.509 16.799 17.821 14.478"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.55 2.18601C13.214 1.39401 11.662 0.932007 9.99995 0.932007C8.33795 0.932007 6.78595 1.39401 5.44995 2.18601"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.8501 4.12598C18.1881 5.70898 19.0001 7.75798 19.0001 10.001C19.0001 10.56 18.9421 11.105 18.8461 11.637"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.9239 10.965C10.6179 11.659 10.6179 12.785 9.9239 13.479C9.2299 14.173 8.1039 14.173 7.4099 13.479C6.7159 12.785 6.7159 11.659 7.4099 10.965C8.1039 10.271 9.2289 10.271 9.9239 10.965Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13.1111 6.889L10.4441 6V12.222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
