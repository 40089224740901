import { useCallback, useState } from "react";
import { ProductCreateDto, ProductDto } from "../../../../dto/api.dto";
import {
  createProduct,
  deleteProduct,
  editProduct,
  getProduct,
  getProducts,
} from "../../../../api/store";

export const useProductManager = () => {
  const [isGettingProducts, setIsGettingProducts] = useState(false);
  const [isGettingProduct, setIsGettingProduct] = useState(false);
  const [isCreatingProduct, setIsCreatingProduct] = useState(false);
  const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [products, setProducts] = useState<ProductDto[]>([]);

  const fetchProducts = useCallback(
    (name?: string, category?: string, sort?: string) => {
      setIsGettingProducts(true);
      getProducts({ name, category, sort })
        .then((items) => {
          setProducts(items);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsGettingProducts(false);
        });
    },
    []
  );

  const fetchProduct = useCallback(async (item: ProductDto) => {
    setIsGettingProduct(true);
    try {
      return await getProduct(item.id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsGettingProduct(false);
    }
  }, []);

  const addNewProduct = useCallback(async (item: ProductCreateDto) => {
    setIsCreatingProduct(true);
    try {
      return await createProduct(item);
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreatingProduct(false);
    }
  }, []);

  const updateProduct = useCallback(async (item: ProductDto) => {
    setIsUpdatingProduct(true);
    try {
      return await editProduct(item);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdatingProduct(false);
    }
  }, []);

  const removeProduct = useCallback(
    async (item: ProductDto) => {
      setIsDeletingProduct(true);
      try {
        await deleteProduct(item.id);
        await fetchProducts();
      } catch (error) {
        console.log(error);
      } finally {
        setIsDeletingProduct(false);
      }
    },
    [fetchProducts]
  );

  return {
    fetchProducts,
    fetchProduct,
    products,
    addNewProduct,
    updateProduct,
    removeProduct,
    isGettingProducts,
    isGettingProduct,
    isCreatingProduct,
    isUpdatingProduct,
    isDeletingProduct,
  };
};
