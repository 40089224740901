import axios, { AxiosRequestConfig } from "axios";
import { UserStore } from "../../stores/UserStore";
const { REACT_APP_BASE_URL } = process.env;

const instance = axios.create({ baseURL: REACT_APP_BASE_URL });
instance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    if (UserStore.token) {
      config.headers = { authorization: `Bearer ${UserStore.token}` };
    }

    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

export { instance as http };
