import { http } from "../plugins/axios/axios";
import {
  CreateKnowledgeBaseItemDto,
  KnowledgeBaseItemDto,
} from "../dto/api.dto";

export const getKnowledgeBaseItems = async (
  baseId: string,
  search?: string
) => {
  const { data } = await http.get<KnowledgeBaseItemDto[]>(
    `/knowledge-base/items/${baseId}`,
    { params: { search } }
  );
  return data;
};

export const createKnowledgeBaseItem = async (
  baseId: string,
  item: CreateKnowledgeBaseItemDto
) => {
  const { data } = await http.post<KnowledgeBaseItemDto>(
    `/knowledge-base/items/${baseId}`,
    item
  );
  return data;
};

export const updateKnowledgeBaseItem = async (
  baseId: string,
  item: KnowledgeBaseItemDto
) => {
  const { data } = await http.patch<KnowledgeBaseItemDto>(
    `/knowledge-base/items/${baseId}/${item.id}`,
    item
  );
  return data;
};

export const deleteKnowledgeBase = async (baseId: string) => {
  const { data } = await http.delete(`/knowledge-base/${baseId}`);
  return data;
};

export const deleteKnowledgeBaseItem = async (
  baseId: string,
  itemId: string
) => {
  const { data } = await http.delete(
    `/knowledge-base/items/${baseId}/${itemId}`
  );
  return data;
};
