import React from "react";

export const RocketIcon = () => {
  return (
    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0996 13.1234L12.7006 17.4426C12.3025 17.761 11.7287 17.7292 11.3682 17.3688L8.63286 14.6334C8.27241 14.273 8.24063 13.6991 8.55907 13.3011L12.8783 7.90206C14.8533 5.43325 17.8435 3.99609 21.0051 3.99609V3.99609C21.5576 3.99609 22.0055 4.444 22.0055 4.99651V4.99651C22.0055 8.15813 20.5684 11.1483 18.0996 13.1234Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17.0021 14.001V18.3848C17.0021 18.7637 16.788 19.1101 16.4491 19.2796L14.0321 20.4881C13.7727 20.6178 13.4698 20.6285 13.2019 20.5176C12.934 20.4066 12.7274 20.1848 12.6357 19.9097L12 18.0026"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.99726 14.0001L6.09031 13.3645C5.81518 13.2728 5.59339 13.0662 5.4824 12.7982C5.37142 12.5303 5.38217 12.2274 5.51186 11.968L6.72037 9.55107C6.88982 9.21216 7.23619 8.99805 7.6151 8.99805H11.9989"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74734 21.5909L3.99609 22.0042L4.40942 19.253C4.5435 18.3605 5.24412 17.6599 6.13663 17.5258V17.5258C6.78157 17.4289 7.43424 17.6438 7.89541 18.105C8.35658 18.5661 8.57151 19.2188 8.4746 19.8637V19.8637C8.34049 20.7562 7.63985 21.4568 6.74734 21.5909V21.5909Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
