import React from "react";

export const UserProfile = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.333 18H7.667C6.746 18 6 17.254 6 16.333V4.667C6 3.746 6.746 3 7.667 3H19.334C20.254 3 21 3.746 21 4.667V16.334C21 17.254 20.254 18 19.333 18V18Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2679 7.16603C16.2439 8.14203 16.2439 9.72503 15.2679 10.702C14.2919 11.678 12.7089 11.678 11.7319 10.702C10.7549 9.72603 10.7559 8.14303 11.7319 7.16603C12.7079 6.18903 14.2909 6.19003 15.2679 7.16603"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 15.434C17.869 15.105 17.666 14.809 17.406 14.568V14.568C16.967 14.16 16.392 13.934 15.792 13.934C14.792 13.934 12.208 13.934 11.208 13.934C10.608 13.934 10.034 14.161 9.594 14.568V14.568C9.334 14.809 9.131 15.105 9 15.434"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 18V19.333C18 20.254 17.254 21 16.333 21H4.667C3.746 21 3 20.254 3 19.333V7.667C3 6.746 3.746 6 4.667 6H6"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
