import React from "react";
import { TypeButtonProps } from "./typeButton.types";
import classNames from "classnames";
import classes from "./tabButtons.module.scss";

export const TabButtons = ({ activeTab, data, changeTab }: TypeButtonProps) => {
  return (
    <div className="row justify-xs-center gc-10">
      {data.map((el) => {
        return (
          <button
            key={el.key}
            className={classNames(
              classes.button,
              el.key === activeTab && classes.active,
              "text_white h1"
            )}
            onClick={(e) => {
              e.preventDefault();
              changeTab(el.key);
            }}
          >
            {el.label}
          </button>
        );
      })}
    </div>
  );
};
