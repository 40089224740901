import { StatisticNumberType } from "../components/common/StatisticNumber/StatisticNumber.types";
import { DynamicStatus } from "../enum/dynamic-statistic-status.enum";

export function mapStatusToDirection(
  status?: DynamicStatus | null
): StatisticNumberType | undefined {
  if (!status) {
    return;
  }

  if (status === DynamicStatus.EQUAL) {
    return;
  }

  return status === DynamicStatus.ASCEND ? "ascending" : "descending";
}
