import React from "react";

export const KeyIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path
          d="M15.0312 9.34177C15.0312 9.13477 14.8633 8.96777 14.6562 8.96777C14.4492 8.96877 14.2812 9.13677 14.2812 9.34377C14.2812 9.55077 14.4492 9.71877 14.6562 9.71777C14.8633 9.71777 15.0312 9.54977 15.0312 9.34277"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M12.499 14.7847L11.469 15.8168H9.637V17.2857H8.172V19.1217L6.798 20.4998H3.5V17.1947L9.223 11.4587C8.417 9.36475 8.849 6.90275 10.534 5.21375C12.814 2.92875 16.511 2.92875 18.791 5.21375C21.071 7.49875 21.071 11.2037 18.791 13.4897C17.089 15.1937 14.601 15.6237 12.499 14.7847V14.7847Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </svg>
  );
};
