import React from "react";

export const MoneyIcon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.74935 14.75C5.69993 14.7501 5.65166 14.7649 5.61063 14.7924C5.56961 14.82 5.53767 14.8591 5.51884 14.9048C5.50002 14.9505 5.49516 15.0007 5.50488 15.0492C5.5146 15.0976 5.53846 15.1421 5.57345 15.177C5.60844 15.2119 5.65299 15.2356 5.70147 15.2452C5.74994 15.2548 5.80018 15.2498 5.84582 15.2309C5.89146 15.212 5.93047 15.1799 5.95791 15.1388C5.98535 15.0977 6 15.0494 6 15C5.99986 14.9336 5.97338 14.87 5.92639 14.8231C5.87939 14.7763 5.81572 14.75 5.74934 14.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10 13.5C10.2967 13.5 10.5867 13.588 10.8334 13.7528C11.08 13.9176 11.2723 14.1519 11.3858 14.426C11.4994 14.7001 11.5291 15.0017 11.4712 15.2926C11.4133 15.5836 11.2704 15.8509 11.0607 16.0607C10.8509 16.2704 10.5836 16.4133 10.2926 16.4712C10.0017 16.5291 9.70006 16.4993 9.42597 16.3858C9.15189 16.2723 8.91762 16.08 8.7528 15.8334C8.58797 15.5867 8.5 15.2967 8.5 15C8.5 14.6022 8.65804 14.2206 8.93934 13.9393C9.22064 13.658 9.60218 13.5 10 13.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17 12V18C17 18.5304 16.7893 19.0391 16.4142 19.4142C16.0391 19.7893 15.5304 20 15 20H4.9989C4.73633 19.9999 4.47634 19.9481 4.23378 19.8476C3.99123 19.747 3.77085 19.5997 3.58523 19.414C3.39962 19.2283 3.2524 19.0078 3.15199 18.7652C3.05157 18.5226 2.99993 18.2626 3 18V11.9989C3.00007 11.7363 3.05186 11.4763 3.15241 11.2338C3.25296 10.9912 3.40029 10.7708 3.58601 10.5852C3.77173 10.3996 3.99219 10.2524 4.2348 10.152C4.47741 10.0516 4.73743 9.99993 5 10H15.0011C15.2637 10.0001 15.5237 10.0519 15.7662 10.1524C16.0088 10.253 16.2292 10.4003 16.4148 10.586C16.6004 10.7717 16.7476 10.9922 16.848 11.2348C16.9484 11.4774 17.0001 11.7374 17 12Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.2507 15.25C14.3001 15.2499 14.3483 15.2351 14.3894 15.2076C14.4304 15.18 14.4623 15.1409 14.4812 15.0952C14.5 15.0495 14.5048 14.9993 14.4951 14.9508C14.4854 14.9024 14.4615 14.8579 14.4265 14.823C14.3916 14.7881 14.347 14.7644 14.2985 14.7548C14.2501 14.7452 14.1998 14.7502 14.1542 14.7691C14.1085 14.788 14.0695 14.8201 14.0421 14.8612C14.0146 14.9023 14 14.9506 14 15C14.0001 15.0664 14.0266 15.13 14.0736 15.1769C14.1206 15.2237 14.1843 15.25 14.2507 15.25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M6.19922 10.0004L7.40954 5.48345L7.40985 5.48245C7.47787 5.22884 7.59518 4.99111 7.75508 4.78283C7.91498 4.57456 8.11434 4.39982 8.34177 4.26859C8.5692 4.13736 8.82026 4.05222 9.08059 4.01801C9.34093 3.98381 9.60546 4.00122 9.85907 4.06925L19.5183 6.65737L19.5193 6.65768C19.7729 6.7257 20.0106 6.84301 20.2189 7.00291C20.4272 7.16281 20.6019 7.36217 20.7331 7.5896C20.8644 7.81703 20.9495 8.06809 20.9837 8.32843C21.0179 8.58877 21.0005 8.85329 20.9324 9.1069L19.3796 14.9024C19.2451 15.4019 18.922 15.8297 18.4784 16.0957C18.0347 16.3617 17.5052 16.445 17.0013 16.3283"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
