import React, { useCallback, useMemo } from "react";
import { InputProps } from "./input.types";
import styles from "./input.module.scss";
import textStyles from "../text/text.module.scss";
import classNames from "classnames";
import { Button } from "../button";
import { Text } from "../text";

export const Input = ({
  value,
  onChange,
  onSubmit,
  type = "text",
  hint,
  status,
  placeholder,
  textPosition = "start",
  blurred,
  clearable = false,
  disabled = false,

  minLength,
  maxLength,

  leftNode,
  rightNode,
}: InputProps) => {
  const classes = useMemo(() => {
    return [
      styles.input,
      styles[textPosition],
      textStyles.text,
      textStyles.p,
      blurred && styles.blurred,
      status && styles[status],
      clearable && styles.clearableInput,
      disabled && styles.disabled,
    ];
  }, [textPosition, blurred, status, clearable, disabled]);

  const isShowIcon = useMemo(() => {
    if (textPosition === "center") {
      return false;
    }

    if (clearable) {
      return true;
    }

    return status === "valid";
  }, [textPosition, clearable, status]);

  const iconName = useMemo(() => {
    if (status === "valid") {
      return "arrowDown";
    }
    if (clearable) {
      return "close";
    }
    return;
  }, [status, clearable]);

  const iconColor = useMemo(() => {
    return iconName === "arrowDown" ? "green" : "white";
  }, [iconName]);

  const onIconClick = useCallback(() => {
    onChange("");
  }, [onChange]);

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Enter" && onSubmit) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const Element = useMemo(() => (type !== "textarea" ? "input" : "textarea"), [
    type,
  ]);

  return (
    <div className="full-width column gr-30">
      <div className={styles.inputPlace}>
        <Element
          className={classNames(classes)}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChangeInput}
          onKeyDown={handleKeyDown}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
        />
        {(isShowIcon || rightNode) && (
          <div
            className={classNames(
              styles.rightNode,
              "row gc-20 items-xs-center"
            )}
          >
            {isShowIcon && (
              <Button
                icon={iconName}
                color={iconColor}
                backgroundColor="transparent"
                size="xs"
                onClick={onIconClick}
              />
            )}

            {!clearable && (rightNode ?? null)}
          </div>
        )}

        {leftNode && (
          <div className={classNames(styles.leftNode, "row items-xs-center")}>
            {leftNode}
          </div>
        )}
      </div>
      {hint && status && (
        <Text type="h6" color={status === "valid" ? "success" : "error"}>
          {hint}
        </Text>
      )}
    </div>
  );
};
