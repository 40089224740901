import React, { useCallback, useEffect, useState } from "react";
import TimePickerBase from "react-time-picker";
import { TimePickerProps } from "./time-picker.types";
import styles from "./time-picker.module.scss";

export const TimePicker = ({ value, onChange }: TimePickerProps) => {
  const [innerValue, setInnerValue] = useState("00:00");

  useEffect(() => {
    const hour = value.getHours();
    const minute = value.getMinutes();

    setInnerValue(
      `${hour < 10 ? "0" : ""}${hour}:${minute < 10 ? "0" : ""}${minute}`
    );
  }, [value]);

  const onChangeTime = useCallback(
    (time) => {
      if (!time) {
        return;
      }
      const newValue = new Date(value);
      const [hour, minute] = time.split(":");

      newValue.setHours(hour);
      newValue.setMinutes(minute);
      onChange(newValue);
    },
    [onChange, value]
  );

  return (
    <TimePickerBase
      value={innerValue}
      // @ts-ignore
      onChange={onChangeTime}
      maxDetail="minute"
      locale="ru"
      clearIcon={null}
      className={[styles.container, "pv-40 ph-30"]}
    />
  );
};
