import {
  BotTransportsDto,
  CreateTelegramTransportDto,
  TelegramTransport,
  TelegramUsernameDto,
} from "../dto/transports.dto";
import { http } from "../plugins/axios/axios";

export async function getBotTransports(
  botId: string
): Promise<BotTransportsDto> {
  const { data } = await http.get<BotTransportsDto>(
    `/bots/transports/${botId}`
  );

  return data;
}

export async function connectTelegramTransport(
  botId: string,
  dto: CreateTelegramTransportDto
) {
  const { data } = await http.post<TelegramTransport>(
    `/bots/transports/${botId}/telegram`,
    dto
  );

  return data;
}

export async function generateTelegramUsername(botId: string): Promise<string> {
  const { data } = await http.get<TelegramUsernameDto>(
    `/bots/transports/${botId}/telegram/username`
  );

  return data.username;
}
