import React, { useMemo } from "react";
import { Icon, Text } from "../../../uikit";
import { Color } from "../../../uikit/types/color.type";
import { StatisticNumberProps } from "./StatisticNumber.types";

export function StatisticNumber({ value, direction }: StatisticNumberProps) {
  const iconType = useMemo(() => {
    if (direction == "ascending") {
      return "ascending";
    }
    if (direction == "descending") {
      return "descending";
    }
    return "ascending";
  }, [direction]);

  const color = useMemo((): Color => {
    if (direction === "ascending") {
      return "green";
    }
    if (direction === "descending") {
      return "red";
    }
    return "white";
  }, [direction]);

  return (
    <div className="row items-xs-center gc-40">
      {direction && <Icon name={iconType} color={color} />}
      <Text type="h3" color={color}>
        {value}
      </Text>
    </div>
  );
}
