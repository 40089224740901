import React, { ReactNode } from "react";
import { IconName } from "../icon.types";
import { AscendingIcon } from "./ascending.icon";
import { BellIcon } from "./bell.icon";
import { CalendarIcon } from "./calendar.icon";
import { ConversationIcon } from "./conversation.icon";
import { DescendingIcon } from "./descending.icon";
import { HelpIcon } from "./help.icon";
import { KeyIcon } from "./key.icon";
import { MoneyIcon } from "./money.icon";
import { RocketIcon } from "./rocket.icon";
import { SettingsIcon } from "./settings.icon";
import { StatisticIcon } from "./statistic.icon";
import { StoreIcon } from "./store.icon";
import { CloseIcon } from "./close.icon";
import { ArrowDownIcon } from "./arrowDown.icon";
import { DocumentIcon } from "./document.icon";
import { ImageIcon } from "./image.icon";
import { VideoIcon } from "./video.icon";
import { AudioIcon } from "./audio.icon";
import { HeartIcon } from "./heart.icon";
import { LinkIcon } from "./link.icon";
import { TrashIcon } from "./trash.icon";
import { DownloadIcon } from "./download.icon";
import { SearchIcon } from "./search.icon";
import { CopyIcon } from "./copy.icon";
import { PencilIcon } from "./pencil.icon";
import { PlusIcon } from "./plus.icon";
import { EditIcon } from "./edit.icon";
import { UserProfile } from "./user-profile.icon";
import { SendIcon } from "./send.icon";
import { ArrowLeftIcon } from "./arrowLeft.icon";
import { ArrowRightIcon } from "./arrowRight.icon";
import { TextIcon } from "./text.icon";
import { EyeIcon } from "./eye.icon";
import { SaveIcon } from "./save.icon";
import { CheckCircleIcon } from "./check-circle.icon";
import { RefreshIcon } from "./refresh.icon";
import { SpaceRocketIcon } from "./spaceRocket.icon";
import { HandButtonIcon } from "./handButton.icon";

const icons: Record<IconName, ReactNode> = {
  key: <KeyIcon />,
  calendar: <CalendarIcon />,
  help: <HelpIcon />,
  conversation: <ConversationIcon />,
  settings: <SettingsIcon />,
  statistics: <StatisticIcon />,
  store: <StoreIcon />,
  ascending: <AscendingIcon />,
  descending: <DescendingIcon />,
  rocket: <RocketIcon />,
  money: <MoneyIcon />,
  bell: <BellIcon />,
  close: <CloseIcon />,
  arrowDown: <ArrowDownIcon />,
  text: <TextIcon />,
  document: <DocumentIcon />,
  image: <ImageIcon />,
  video: <VideoIcon />,
  audio: <AudioIcon />,
  heart: <HeartIcon />,
  link: <LinkIcon />,
  trash: <TrashIcon />,
  download: <DownloadIcon />,
  search: <SearchIcon />,
  copy: <CopyIcon />,
  pencil: <PencilIcon />,
  plus: <PlusIcon />,
  edit: <EditIcon />,
  "user-profile": <UserProfile />,
  send: <SendIcon />,
  arrowLeft: <ArrowLeftIcon />,
  arrowRight: <ArrowRightIcon />,
  eye: <EyeIcon />,
  save: <SaveIcon />,
  refresh: <RefreshIcon />,
  "scape-rocket": <SpaceRocketIcon />,
  "hand-button": <HandButtonIcon />,
  ["check-circle"]: <CheckCircleIcon />,
};

export { icons };
