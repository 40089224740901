import React from "react";

export const ArrowRightIcon = () => {
  return (
    <svg
      style={{ transform: "rotate(-90deg)" }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 11L12 14L15 11"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
