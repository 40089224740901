import React from "react";
import { Text } from "../text";
import { CheckboxProps } from "./checkbox.types";
import { CheckedIcon } from "./icons/CheckedIcon";
import { UnCheckedIcon } from "./icons/UnCheckedIcon";
import classNames from "classnames";
import classes from "./checkbox.module.scss";

export function Checkbox({ value, onChange, label }: CheckboxProps) {
  return (
    <div className="row gc-20 pointer" onClick={() => onChange(!value)}>
      <div
        className={classNames([
          classes.container,
          "row justify-xs-center items-xs-center",
          "border_primary",
        ])}
      >
        {value ? <CheckedIcon /> : <UnCheckedIcon />}
      </div>
      <Text type="h3">{label}</Text>
    </div>
  );
}
