import { UserAuthEntity } from "./user-auth.model";
import { UserInfoEntity } from "./user-info.model";

export class UserEntity {
  constructor(
    private readonly _id: string,
    private readonly _auth: UserAuthEntity,
    private readonly _info: UserInfoEntity
  ) {}

  public get id() {
    return this._id;
  }

  public get auth() {
    return this._auth;
  }

  public get info() {
    return this._info;
  }

  static fromJSON(obj: any = {}) {
    return new UserEntity(
      obj.id,
      UserAuthEntity.fromJSON(obj.auth),
      UserInfoEntity.fromJSON(obj.info)
    );
  }
}
