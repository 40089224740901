import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  Input,
  Modal,
  Text,
  FilePicker,
  FilePreview,
  Button,
} from "../../../uikit";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  CategoryDto,
  ProductCreateDto,
  ProductDto,
} from "../../../dto/api.dto";
import { uploadFile } from "../../../api/files";
import { LocaleNamespace } from "../../../plugins/i18n/namespaces";
import { useTranslation } from "react-i18next";

export type AddNewProductModalProps = {
  show: boolean;
  hide: () => void;
  product: {
    title: string;
    description: string;
    image: string;
    price: number | undefined;
    categoryId: string;
    id: string;
  };
  categories: Array<CategoryDto>;
  onSave: (product: ProductDto | ProductCreateDto) => void;
  onRemove?: (product: ProductDto) => void;
};

export type ProductFormData = {
  title: string;
  description: string;
  price: number | undefined;
  categoryId: string;
  image: string[];
};

const productFormValidation = yup.object().shape({
  title: yup.string().required(),
  description: yup.string(),
  image: yup.array(),
  categoryId: yup.string().required(),
  price: yup
    .string()
    .required()
    .test("is-number", "Поле должно состоять из цифр", (value) =>
      value ? !isNaN(Number.parseInt(value)) : false
    ),
});

export const ProductModal = ({
  show = false,
  hide,
  product,
  categories = [],
  onSave,
  onRemove,
}: AddNewProductModalProps) => {
  const { t } = useTranslation(LocaleNamespace.COMPANY__CREATE_PRODUCT_MODAL);
  const { t: tBtn } = useTranslation(LocaleNamespace.BUTTONS);

  const [image, setImage] = useState<File[]>([]);
  const [currentCategory, setCurrentCategory] = useState<CategoryDto>();

  const onSubmit = useCallback(
    async (value: ProductFormData) => {
      if (image.length) {
        const link = await uploadFile(image[0]);
        value.image[0] = link.path;
        setImage([]);
      }
      if (product.id) {
        const newItem: ProductDto = {
          id: product.id,
          title: value.title,
          price: value.price ?? 0,
          categoryId: value.categoryId,
          description: value.description,
          image: value.image[0],
        };
        onSave(newItem);
        return;
      }

      const newItem: ProductCreateDto = {
        title: value.title,
        price: value.price ?? 0,
        categoryId: value.categoryId,
        description: value.description,
        image: value.image[0],
      };
      onSave(newItem);
    },
    [image, onSave, product.id]
  );

  const productForm = useFormik<ProductFormData>({
    initialValues: {
      categoryId: "",
      title: "",
      description: "",
      price: undefined,
      image: [],
    },
    validationSchema: productFormValidation,
    onSubmit,
  });

  const removeImage = useCallback(() => {
    if (product.id) {
      productForm.setFieldValue("image", []);
    }
    setImage([]);
  }, [product.id, productForm]);

  useEffect(() => {
    productForm.setValues({
      categoryId: product.categoryId ?? "",
      description: product.description ?? "",
      price: product.price,
      title: product.title ?? "",
      image: product.image.length ? [product.image] : [],
    });
    if (product.categoryId && categories.length) {
      const foundedCategory = categories?.find(
        (item) => item.id === product.categoryId
      );
      setCurrentCategory(foundedCategory);
    }
  }, [categories, product]);

  const isImagePicker = useMemo(() => {
    return image.length === 0 && productForm.values.image.length === 0;
  }, [image, productForm]);

  const setCategory = useCallback(
    (category: CategoryDto) => {
      productForm.setFieldValue("categoryId", category.id);
      setCurrentCategory(category);
    },
    [productForm]
  );

  const remove = useCallback(() => {
    if (onRemove) {
      onRemove({ ...product, price: product.price ?? 0 });
    }
  }, [product, onRemove]);

  return (
    <div>
      <Modal
        isShow={show}
        onClose={hide}
        title={<Text type="h2">{t("title")}</Text>}
      >
        <form
          className="mt-20 pa-30 column gr-10"
          onSubmit={productForm.handleSubmit}
        >
          <Input
            value={productForm.values.title}
            blurred
            onChange={productForm.handleChange("title")}
            placeholder={t("fields.name.placeholder")}
          />
          <div className="column gr-20">
            {isImagePicker && (
              <FilePicker type="image" value={image} onChange={setImage} />
            )}
            {!isImagePicker && (
              <div className="column gr-20">
                <Text type="h3">{t("fields.picture.label")}</Text>
                <FilePreview
                  type="image"
                  editable
                  links={productForm.values.image}
                  files={image}
                  onRemove={removeImage}
                />
              </div>
            )}
          </div>
          <Input
            value={productForm.values.description}
            blurred
            type="textarea"
            onChange={productForm.handleChange("description")}
            placeholder={t("fields.description.placeholder")}
          />
          <Input
            value={productForm.values.price}
            blurred
            onChange={productForm.handleChange("price")}
            placeholder={t("fields.price.placeholder")}
            type="number"
          />
          <div className="column gr-20">
            <Text type="h3">{t("fields.category.label")}</Text>
            <Dropdown
              outline="black-lightest-1"
              color="black-lightest-2-5"
              options={categories}
              getLabel={(category: CategoryDto) => category.title}
              onChange={setCategory}
              selected={currentCategory}
            />
          </div>
          <div className="row justify-xs-end items-xs-center gc-10">
            {product.id && (
              <Button
                icon="trash"
                label={tBtn("delete")}
                flat
                onClick={() => remove()}
              />
            )}
            <Button
              label={tBtn("save")}
              color="blue"
              type="submit"
              disabled={!productForm.dirty || !productForm.isValid}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};
