import React, { FC, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Modal, Text } from "../../../uikit";
import {
  BookingOperatorDto,
  BookingProvidedData,
  BookingUserDataRequestDto,
} from "../../../dto/api.dto";
import { NotificationType } from "../../../enum/notification-type.enum";
import { useTranslation } from "react-i18next";
import { LocaleNamespace } from "../../../plugins/i18n/namespaces";
import { TableColumn } from "../../../uikit/components/table/table.types";
import { Table } from "../../../uikit/components/table/table";

export type OperatorBookingModalProps = {
  show: boolean;
  hide: () => void;
  read: (id: string) => void;
  operatorBooking: BookingOperatorDto | null;
};

export const OperatorBookingModal: FC<OperatorBookingModalProps> = observer(
  ({ show, hide, operatorBooking, read }) => {
    const { t } = useTranslation(LocaleNamespace.OPERATOR__BOOKING_PAGE);

    useEffect(() => {
      if (!show) {
        return;
      }

      if (!operatorBooking) {
        return;
      }

      if (operatorBooking.status === NotificationType.VIEWED) {
        return;
      }

      read(operatorBooking.id);
    }, [operatorBooking, read, show]);

    const questionsByIds = useMemo(() => {
      if (!operatorBooking) {
        return {};
      }

      const data: Record<string, BookingUserDataRequestDto> = {};

      for (const answer of (operatorBooking.booking
        .answers as unknown) as BookingUserDataRequestDto[]) {
        data[answer.id] = answer;
      }

      return data;
    }, [operatorBooking]);

    const dataColumns: TableColumn<BookingProvidedData>[] = useMemo(
      () => [
        {
          label: "request",
          renderCell: (item) => {
            if (
              !questionsByIds[item.answerId] ||
              questionsByIds[item.answerId].text.length === 0
            ) {
              return <Text type="h3"> </Text>;
            }

            return <Text type="h3">{questionsByIds[item.answerId].text}</Text>;
          },
        },
        {
          label: "data",
          renderCell: (item) => <Text type="h3">{item.data}</Text>,
        },
      ],
      [questionsByIds]
    );

    if (!operatorBooking) {
      return null;
    }

    return (
      <Modal
        isShow={show}
        onClose={hide}
        title={<Text type="h2">{t("show-booking.title")}</Text>}
      >
        <div className="mt-30 column gr-30">
          <Table columns={dataColumns} data={operatorBooking.providedData} />
        </div>
      </Modal>
    );
  }
);
