/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import { DatePickerProps } from "./date-picker.types";
import DatePickerBase, {
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../button";
import { Text } from "../text";
import { months } from "./data";

import styles from "./date-picker.module.scss";

function DatePickerHeader({
  date,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  increaseMonth,
  decreaseMonth,
}: ReactDatePickerCustomHeaderProps) {
  // @ts-ignore
  const month = months?.[date.getMonth()] ?? "";
  return (
    <div className="row justify-xs-center items-xs-center gc-10">
      <Button
        icon="arrowLeft"
        onlyIcon
        color="black-lightest-5"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <Text type="h2" color="black">
        {month} {date.getFullYear()}
      </Text>
      <Button
        icon="arrowRight"
        onlyIcon
        color="black-lightest-5"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </div>
  );
}

function Day(day: number) {
  return <Text type="h3">{day}</Text>;
}

function CalendarWrapper({ className, children }: CalendarContainerProps) {
  return <div className={className}>{children}</div>;
}

export const DatePicker = ({
  value,
  onChange,
  full = false,
}: DatePickerProps) => {
  // @ts-ignore
  const Input = forwardRef(({ value: date, onClick }, ref) => (
    // @ts-ignore
    <div ref={ref}>
      <Button
        label={new Date(date).toLocaleDateString()}
        onClick={onClick}
        icon="arrowDown"
        outline="black-lightest-0"
        color="black-lightest-3"
        iconPosition="right"
        full={full}
      />
    </div>
  ));

  return (
    <div>
      <DatePickerBase
        selected={value}
        onChange={onChange}
        customInput={<Input />}
        renderCustomHeader={DatePickerHeader}
        renderDayContents={Day}
        calendarContainer={CalendarWrapper}
        calendarClassName={styles.calendar}
        dayClassName={(date) =>
          date.getMonth() === value.getMonth() &&
          date.getDate() === value.getDate() &&
          date.getFullYear() === value.getFullYear()
            ? "bg_primary"
            : "bg_transparent bg_hover_black-lightest-4"
        }
      />
    </div>
  );
};
