import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate } from "react-router";
import { UserStore } from "../../stores/UserStore";
import { FullPageLoader } from "../../components/common/FullPageLoader/FullPageLoader";
import { Toaster } from "react-hot-toast";
import { UserRole } from "../../enum/roles.enum";
import { SocketStore } from "../../stores/SocketStore";

export const AuthCheckerLayout = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    UserStore.fetchToken();
    UserStore.fetchUser()
      .then((user) => {
        const { pathname } = window.location;

        SocketStore.connect();

        if (
          user.auth.role === UserRole.COMPANY &&
          (pathname === "/company/telegram-auth" ||
            !pathname.includes("/company"))
        ) {
          navigate("/company");
        }
        if (
          user.auth.role === UserRole.OPERATOR &&
          !pathname.includes("/operator")
        ) {
          navigate("/operator");
        }
      })
      .catch(() => {
        navigate("/auth");
      });
  }, []);

  if (UserStore.isLoading) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <Outlet />
      <Toaster position="bottom-right" />
    </div>
  );
});
