import React from "react";

export function CheckerRadioButtonIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        fill="black"
        fillOpacity="0.1"
        stroke="#01C29A"
      />
      <circle cx="12" cy="12" r="6" fill="#01C29A" />
    </svg>
  );
}
