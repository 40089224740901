import React from "react";
import { Navigate, Route } from "react-router-dom";
import { Router } from "../../interfaces/router/router.interface";

function toRoute(router: Router, parentPath?: string) {
  if (router.guards && !router.guards.every((guard) => guard.canActive())) {
    return null;
  }

  const key = `${parentPath}-${router.path}`;

  if (router.redirect) {
    return (
      <Route
        key={key}
        path={router.path}
        element={<Navigate to={router.redirect} />}
      ></Route>
    );
  }

  if (!router.children) {
    return (
      <Route key={key} path={router.path} element={router.component}></Route>
    );
  }

  return (
    <Route key={key} path={router.path} element={router.component}>
      {router.children.map((r) => toRoute(r, router.path)).filter(Boolean)}
    </Route>
  );
}

function generateRoutes(routes: Router[]) {
  return routes.map((r) => toRoute(r, "")).filter(Boolean);
}

export const useRouter = (routes: Router[]) => {
  return {
    routes: generateRoutes(routes),
  };
};
