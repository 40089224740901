import { FileDto } from "../dto/api.dto";
import { http } from "../plugins/axios/axios";

export async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const { data } = await http.post<FileDto>("/files", formData);
  return data;
}
