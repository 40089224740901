import React from "react";
import { useCallback } from "react";
import { Card } from "../card";
import { TableProps } from "./table.types";

export const Table = <T extends object>({ columns, data }: TableProps<T>) => {
  const getCells = useCallback(
    (item: T) => {
      return columns.map((column) => column.renderCell(item));
    },
    [columns]
  );

  return (
    <table className="full-width">
      <tbody>
        {data.map((row, i) => {
          return (
            <tr key={i}>
              <Card padding="xs">
                <div className="full-width row justify-xs-between items-xs-center">
                  {getCells(row).map((cell, j) => {
                    return <td key={j}>{cell}</td>;
                  })}
                </div>
              </Card>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
