import React from "react";
import { OverlayLoadingProps } from "./overlay-loading.types";
import styles from "./overlay-loading.module.scss";
import { Overlay } from "../overlay";
import { Loader } from "../loader";
import classNames from "classnames";

export function OverlayLoading({ children, loading }: OverlayLoadingProps) {
  return (
    <div className={classNames(styles.container, "full-height")}>
      {loading && (
        <Overlay>
          <div className="row justify-xs-center item-xs-center">
            <Loader size="md" />
          </div>
        </Overlay>
      )}

      {children}
    </div>
  );
}
