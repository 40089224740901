import React from "react";

export const HandButtonIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7" clipPath="url(#clip0_43_99)">
        <path
          d="M12.472 11.908V7.477C12.472 6.661 11.811 6 10.995 6C10.18 6 9.51903 6.661 9.51903 7.477V10.923V16L6.87303 14.642C6.27103 14.333 5.53803 14.448 5.06003 14.926C4.42703 15.559 4.45703 16.594 5.12503 17.19L9.95003 21.493C10.316 21.819 10.79 22 11.281 22H15.914C16.851 22 17.663 21.349 17.866 20.434L19.055 15.083C19.306 13.955 18.551 12.85 17.409 12.673L12.472 11.908V11.908Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.643 9H17C18.105 9 19 8.105 19 7V5C19 3.895 18.105 3 17 3H5C3.895 3 3 3.895 3 5V7C3 8.105 3.895 9 5 9H9.357"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_99">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
