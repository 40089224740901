import React from "react";
import { Text } from "../text";
import { CheckerRadioButtonIcon } from "./icons/checked.icon";
import { UnCheckerRadioButtonIcon } from "./icons/unchecked.icon";
import { RadioButtonProps } from "./radio-button.types";

export function RadioButton({ value, onClick, label }: RadioButtonProps) {
  return (
    <div className="row gc-30" onClick={onClick}>
      {value ? <CheckerRadioButtonIcon /> : <UnCheckerRadioButtonIcon />}
      <Text type="h5" color="grey">
        {label}
      </Text>
    </div>
  );
}
