import React from "react";
import { ToastProps } from "./Toast.types";
import { Text } from "../../../uikit";

export const Toast = ({ message }: ToastProps) => {
  return <Text type="h3">{message}</Text>;
};

export function generateToast(message: string) {
  return <Toast message={message} />;
}
