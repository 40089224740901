import React from "react";
import { ModalProps } from "./modal.types";
import { Overlay } from "../overlay";
import { Card } from "../card";
import { Button } from "../button";
import classNames from "classnames";
import classes from "./modal.module.scss";

export const Modal = ({
  isShow,
  onClose,
  children,
  title,
  fullscreen,
}: ModalProps) => {
  if (!isShow) {
    return <></>;
  }

  return (
    <Overlay>
      <div
        className={classNames({
          [classes.container]: !fullscreen,
          "full-width": fullscreen,
          "full-height": fullscreen,
        })}
      >
        <Card
          color="black-lightest-4"
          fullHeight={fullscreen}
          fullWidth={fullscreen}
        >
          <div
            className={classNames([
              classes.modal,
              "full-width row items-xs-center ml-30",
              !title ? "justify-xs-end" : "justify-xs-between",
            ])}
          >
            {title}

            <Button
              icon="close"
              size="xs"
              backgroundColor="transparent"
              onlyIcon
              onClick={onClose}
            />
          </div>
          {children}
        </Card>
      </div>
    </Overlay>
  );
};
