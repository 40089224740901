import React from "react";

export const SearchIcon = () => {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.934 8.467C15.934 12.591 12.591 15.934 8.467 15.934C4.343 15.934 1 12.591 1 8.467C1 4.343 4.343 1 8.467 1C12.591 1 15.934 4.343 15.934 8.467V8.467Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17 17L13.75 13.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
