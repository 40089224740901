import React, { useCallback, useState } from "react";
import { Button, Card, Checkbox, Tabs } from "../../uikit";
import { Tab } from "../../uikit";
import classNames from "classnames";
import classes from "./AuthPage.module.scss";
import { useTranslation } from "react-i18next";
import { LocaleNamespace } from "../../plugins/i18n/namespaces";
import { Input } from "../../uikit/components/input";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserStore } from "../../stores/UserStore";
import { ToastStore } from "../../stores/ToastStore";
import axios from "axios";
import { UserRole } from "../../enum/roles.enum";
import { useNavigate } from "react-router";
import { CacheService } from "../../services/cache.service";
import { BotsStore } from "../../stores/BotsStore";
import { SocketStore } from "../../stores/SocketStore";
import { resetPassword } from "../../api/auth";

export interface SignInFormData {
  email: string;
  password: string;
  isOperator: boolean;
}

export interface SignUpFormData {
  email: string;
}

export interface RegistrationFormData {
  email: string;
}

const signInValidationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required(),
  isOperator: yup.bool().required(),
});

const signUpValidationSchema = yup.object({
  email: yup.string().required().email(),
});

const reloadTimeout = 3000;

export const AuthPage = () => {
  const { t } = useTranslation(LocaleNamespace.COMPANY__AUTH_PAGE);
  const navigate = useNavigate();

  const [isShowPassword, setIsShowPassword] = useState(false);

  const onSignIn = useCallback(
    async ({ email, password, isOperator }: SignInFormData) => {
      try {
        const status = await UserStore.signIn(
          email,
          password,
          !isOperator ? UserRole.COMPANY : UserRole.OPERATOR
        );

        if (!status) {
          ToastStore.toastFailure(t("errors.not-valid-auth"));
          return;
        }

        UserStore.fetchToken();
        const user = await UserStore.fetchUser();

        SocketStore.connect();

        const { pathname } = window.location;

        if (!user.info.isFilledBusinessInfo) {
          CacheService.set("visit-profile" + UserStore.user?.id, false);
        }

        if (
          user.auth.role === UserRole.COMPANY &&
          (pathname === "/company/profile" || !pathname.includes("/company"))
        ) {
          await BotsStore.fetchBots();
          navigate("/company");
        }
        if (
          user.auth.role === UserRole.OPERATOR &&
          !pathname.includes("/operator")
        ) {
          navigate("/operator");
        }
      } catch (error) {
        navigate("/auth");
      }
    },
    [navigate, t]
  );
  const onSignUp = useCallback(
    ({ email }: SignUpFormData) => {
      UserStore.signUp(email)
        .then(() => {
          CacheService.set("visit-profile" + UserStore.user?.id, false);

          ToastStore.toastSuccess(t("notifications.registration-success"));

          setTimeout(() => {
            location.reload();
          }, reloadTimeout);
        })
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response) {
            const { status } = error.response;
            if (status === 409) {
              ToastStore.toastFailure(t("registration-errors.conflict"));
              return;
            }
          }

          ToastStore.toastFailure(t("errors.not-valid-registration"));
        });
    },
    [t]
  );

  const onRestorePassword = useCallback(
    (email: string) => {
      resetPassword(email)
        .then(() => {
          ToastStore.toastSuccess(t("notifications.restore-password-success"));

          setTimeout(() => {
            location.reload();
          }, reloadTimeout);
        })
        .catch((error) => {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            ToastStore.toastFailure(
              t("notifications.restore-password-email-not-found")
            );

            return;
          }

          ToastStore.toastFailure(t("notifications.restore-password-fail"));
        });
    },
    [t]
  );

  const onToggleShowPassword = useCallback(() => {
    setIsShowPassword((value) => !value);
  }, []);

  const signInForm = useFormik<SignInFormData>({
    initialValues: { email: "", password: "", isOperator: false },
    validationSchema: signInValidationSchema,
    onSubmit: (values) => {
      onSignIn(values);
    },
  });

  const signUpForm = useFormik<RegistrationFormData>({
    initialValues: {
      email: "",
    },
    validationSchema: signUpValidationSchema,
    onSubmit: (values) => {
      onSignUp(values);
    },
  });

  const restorePasswordForm = useFormik<RegistrationFormData>({
    initialValues: {
      email: "",
    },
    validationSchema: signUpValidationSchema,
    onSubmit: (values) => {
      onRestorePassword(values.email);
    },
  });

  const tabs: Array<Tab> = [
    {
      label: t("tabs.signIn"),
      key: "0",
      render: () => {
        return (
          <div>
            <form
              className="column gr-20 items-xs-center"
              onSubmit={signInForm.handleSubmit}
            >
              <Input
                value={signInForm.values.email}
                onChange={signInForm.handleChange("email")}
                textPosition="center"
                blurred
                placeholder={t("placeholders.email")}
                status={!signInForm.errors.email ? undefined : "invalid"}
              />

              <Input
                value={signInForm.values.password}
                onChange={signInForm.handleChange("password")}
                textPosition="center"
                blurred
                placeholder={t("placeholders.password")}
                type={!isShowPassword ? "password" : "text"}
                status={!signInForm.errors.password ? undefined : "invalid"}
                rightNode={
                  <Button
                    icon="eye"
                    onlyIcon
                    noPadding
                    backgroundColor="transparent"
                    onClick={onToggleShowPassword}
                  />
                }
              />

              <Checkbox
                value={signInForm.values.isOperator}
                onChange={(isOperator) =>
                  signInForm.setFieldValue("isOperator", isOperator)
                }
                label={t("placeholders.is-operator")}
              />

              <div className="mt-20 full-width">
                <Button
                  type="submit"
                  label={t("actions.enter")}
                  disabled={!signInForm.dirty || !signInForm.isValid}
                  full
                />
              </div>
            </form>
          </div>
        );
      },
    },
    {
      label: t("tabs.registration"),
      key: "1",
      render: () => {
        return (
          <div>
            <form
              className="column gr-20 items-xs-center"
              onSubmit={signUpForm.handleSubmit}
            >
              <Input
                value={signUpForm.values.email}
                onChange={signUpForm.handleChange("email")}
                textPosition="center"
                blurred
                placeholder={t("placeholders.email")}
                status={!signUpForm.errors.email ? undefined : "invalid"}
              />

              <div className="mt-20 full-width">
                <Button
                  type="submit"
                  label={t("actions.send")}
                  disabled={!signUpForm.dirty || !signUpForm.isValid}
                  full
                />
              </div>
            </form>
          </div>
        );
      },
    },
    {
      label: t("tabs.restore-password"),
      key: "2",
      render: () => {
        return (
          <div>
            <form
              className="column gr-20 items-xs-center"
              onSubmit={restorePasswordForm.handleSubmit}
            >
              <Input
                value={restorePasswordForm.values.email}
                onChange={restorePasswordForm.handleChange("email")}
                textPosition="center"
                blurred
                placeholder={t("placeholders.email")}
                status={
                  !restorePasswordForm.errors.email ? undefined : "invalid"
                }
              />

              <div className="mt-20 full-width">
                <Button
                  type="submit"
                  label={t("actions.restore")}
                  disabled={
                    !restorePasswordForm.dirty || !restorePasswordForm.isValid
                  }
                  full
                />
              </div>
            </form>
          </div>
        );
      },
    },
  ].filter((tab) => tab.key !== "1");

  return (
    <div
      className={classNames(
        classes.authPage,
        "row justify-xs-center items-xs-center"
      )}
    >
      <div className={classNames(classes.tabs, "mt-10")}>
        <Card>
          <Tabs tabs={tabs}></Tabs>
        </Card>
      </div>
    </div>
  );
};

export default AuthPage;
