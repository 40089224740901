import {
  NewUnansweredQuestionCountDto,
  StatisticMailingDto,
  StatisticMailingHistoryDto,
  StatisticOperatorDto,
  StatisticOperatorOrdersDto,
  StatisticsDto,
} from "../dto/api.dto";
import { http } from "../plugins/axios/axios";

export async function getCommonBotStatistics(botId: string, days: number) {
  const { data } = await http.get<StatisticsDto>(
    `/statistics/common/${botId}`,
    {
      params: { days },
    }
  );
  return data;
}

export async function getNewUnansweredQuestionsCountStatistics(
  botId: string,
  days: number
) {
  const { data } = await http.get<NewUnansweredQuestionCountDto>(
    `/statistics/common/${botId}/unanswered-count`,
    {
      params: { days },
    }
  );
  return data;
}

export async function getMailingHistoryStatistics(
  mailingId: string,
  { limit, skip }: { limit?: number; skip?: number } = {}
) {
  const { data } = await http.get<StatisticMailingHistoryDto>(
    `/statistics/mailings/${mailingId}/history`,
    {
      params: { limit, skip },
    }
  );
  return data;
}

export async function getMailingStatistics(botId: string, days = 7) {
  const { data } = await http.get<StatisticMailingDto>(
    `/statistics/mailings/${botId}`,
    {
      params: { days },
    }
  );
  return data;
}

export async function getOperatorStatistics(days = 7) {
  const { data } = await http.get<StatisticOperatorDto>(
    `/statistics/operator`,
    {
      params: { days },
    }
  );
  return data;
}

export async function getOperatorProductsStatistics(days = 7) {
  const { data } = await http.get<StatisticOperatorOrdersDto>(
    `/statistics/operator/products`,
    {
      params: { days },
    }
  );
  return data;
}
